import React, { useState } from 'react';
import './CustomStatusSelect.css';

const CustomStatusSelect = ({ options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    return (
        <div className="dropdown-container">
            <div className="dropdown-header d-flex justify-content-evenly align-items-center" onClick={toggling}>
                <span className="font-size-18">{selectedOption.icon}</span>
                <span className='font-size-13'>{selectedOption.label}</span>
                <i className="bx bx-chevron-down font-size-18"></i>
            </div>
            {isOpen && (
                <div className="dropdown-list-container">
                    <ul className="dropdown-list">
                        {options.map(option => (
                            <li className="dropdown-list-item" key={option.value} onClick={onOptionClicked(option)}>
                                {option.icon}
                                <span>{option.label}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomStatusSelect;
