import React, { useEffect, useState } from "react";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Container, Row, Col, CardBody, Card, Button, Form, Label, Input, FormFeedback } from "reactstrap";

// import images
import profileImg from "../../assets/images/profile-img.png";
// import logoImg from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import avatar from "../../assets/images/users/avatar-1.jpg";
import logoImg from "assets/images/favicon-logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BASE_URL from "Config/config";

const LockScreen = () => {

  //meta title
  document.title = "Lock Screen 2 | Skote - React Admin & Dashboard Template";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const [userData, setUserData] = useState([]);
  const [csrfToken, setCsrfToken] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/csrf-token');
      const data = await response.json();
      setCsrfToken(data.csrf_token);
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };
  const token = localStorage.getItem('token');
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user-data`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        }
      });
      console.log('Response Data:', response.data)
      setUserData(response.data.data);
      console.log('Data:', response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkPassword = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/check-password`, { password }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        }
      });
      console.log('Password Match:', response.data);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error checking password:', error);

    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-danger bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-danger p-4">
                        <h5 className="text-danger">Lock screen</h5>
                        <p>Enter your password to unlock the screen!</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal"
                      onSubmit={checkPassword}
                    >
                      {userData && (
                        <div className="user-thumb text-center mb-4">
                          <img
                            src={avatar}
                            className="rounded-circle img-thumbnail avatar-md"
                            alt="thumbnail"
                          />
                          <h5 className="font-size-15 mt-3">{userData.name}</h5>
                        </div>
                      )}

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="d-flex">
                              <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="Enter Password"
                                value={password}
                                onChange={handlePasswordChange}
                              />
                              <button
                                className="btn btn-light"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                <i className={`mdi ${showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}></i>
                              </button>
                            </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-end">
                        <Col xs="12" className="text-end">
                          <Button
                            color="primary"
                            className=" w-md "
                            type="button"
                            onClick={checkPassword}
                          >
                            Unlock
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Not you ? return{" "}
                  <Link
                    to="/login"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Sign In{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {(new Date().getFullYear())} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default LockScreen;
