import React, { useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
} from "reactstrap";

function ViewApiKey() {
    document.title = "View Key API";

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        apiName: '',
        apiDescription: '',
        apiKey: '',
        postedBy: '',
        createdAt: ''
    });
    const [editingRowData, setEditingRowData] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [apiKeys, setApiKeys] = useState([
        {
            id: 1,
            apiName: 'Key Test',
            apiDescription: 'Testing Description',
            apiKey: 'MGbRcgFXCDy8tp3JlejzA5ZBo9S7sLOQ',
            ip: '192.168.1.1',
            postedBy: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm'
        },
        {
            id: 2,
            apiName: 'Key Two',
            apiDescription: 'This is my second key api',
            apiKey: 'Ft6Xmdz0k3pDGQxNYj7eKifEIRCy4naO',
            ip: '192.168.12.1',
            postedBy: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm'
        }
    ]);

    const [newApiKeyData, setNewApiKeyData] = useState({
        apiName: '',
        apiDescription: '',
        apiKey: '',
        postedBy: '',
        createdAt: ''
    });

    const [editModal, setEditModal] = useState(false); 
    const [createModal, setCreateModal] = useState(false); 

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const toggleCreateModal = () => {
        setCreateModal(!createModal);
        if (!createModal) {
            setNewApiKeyData({
                apiName: '',
                apiDescription: '',
                apiKey: '',
                postedBy: '',
                createdAt: ''
            });
        }
    };

    const handleEditClick = (rowData) => {
        setEditingRowData(rowData);
        setModalData(rowData); 
        toggleEditModal(); 
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSaveModal = () => {
        const updatedApiKeys = apiKeys.map(apiKey => {
            if (apiKey.id === modalData.id) {
                return modalData;
            }
            return apiKey;
        });
        setApiKeys(updatedApiKeys);
        toggleEditModal(false);
    };

    const handleDeleteClick = (rowData) => {
        setDeleteModal(true);
        setEditingRowData(rowData);
    };

    const handleDeleteApiKey = () => {
        const updatedApiKeys = apiKeys.filter(apiKey => apiKey.id !== editingRowData.id);
        setApiKeys(updatedApiKeys);
        setDeleteModal(false);
    };

    const handleCreateInputChange = (event) => {
        const { name, value } = event.target;
        setNewApiKeyData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCreateSubmit = () => {
        const newApiKey = {
            ...newApiKeyData,
            id: Math.max(...apiKeys.map(apiKey => apiKey.id), 0) + 1
        };
        setApiKeys([...apiKeys, newApiKey]);
        toggleCreateModal(false);
        setNewApiKeyData({
            apiName: '',
            apiDescription: '',
            apiKey: '',
            postedBy: '',
            createdAt: ''
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Keys" breadcrumbItem="View Key API" />
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            {!!editingRowData ? "Edit API Key" : "Add API Key"}
                        </ModalHeader>
                        <div className="modal-body">
                            <Label>API ID</Label>
                            <Input value={modalData.id} name="id" onChange={handleInputChange} />
                            <Label>API Name</Label>
                            <Input value={modalData.apiName} name="apiName" onChange={handleInputChange} />
                            <Label>API Description</Label>
                            <Input value={modalData.apiDescription} name="apiDescription" onChange={handleInputChange} />
                            <Label>API Key</Label>
                            <Input value={modalData.apiKey} name="apiKey" onChange={handleInputChange} />
                            <Label>Posted By</Label>
                            <Input value={modalData.postedBy} name="postedBy" onChange={handleInputChange} />
                            <Label>Created At</Label>
                            <Input value={modalData.createdAt} name="createdAt" onChange={handleInputChange} />
                        </div>
                        <div className="modal-footer">
                            <Button color="secondary" onClick={handleSaveModal}>Update API Key</Button>
                        </div>
                    </Modal>

                    <Modal isOpen={createModal} toggle={toggleCreateModal}>
                        <ModalHeader toggle={toggleCreateModal}>Add New API Key</ModalHeader>
                        <div className="modal-body">
                            <Label>API Name</Label>
                            <Input value={newApiKeyData.apiName} name="apiName" onChange={handleCreateInputChange} />
                            <Label>API Key</Label>
                            <Input value={newApiKeyData.apiKey} name="apiKey" onChange={handleCreateInputChange} />
                            <Label>API Description</Label>
                            <Input value={newApiKeyData.apiDescription} name="apiDescription" onChange={handleCreateInputChange} />
                            <Label>Posted By</Label>
                            <Input value={newApiKeyData.postedBy} name="postedBy" onChange={handleCreateInputChange} />
                            <Label>Created At</Label>
                            <Input value={newApiKeyData.createdAt} name="createdAt" onChange={handleCreateInputChange} />
                        </div>
                        <div className="modal-footer">
                            <Button color="secondary" onClick={toggleCreateModal}>Cancel</Button>
                            <Button color="primary" onClick={handleCreateSubmit}>Create</Button>
                        </div>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2 row">
                                        <div className="col-sm-2">
                                            <select className="form-select pageSize mb-2">
                                                <option value="10">Show 10</option>
                                                <option value="20">Show 20</option>
                                                <option value="30">Show 30</option>
                                                <option value="40">Show 40</option>
                                                <option value="50">Show 50</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <input className="form-control search-box me-2 mb-2 d-inline-block" placeholder="26 records..." />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-sm-end">

                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addapiKey-modal btn btn-secondary"
                                                    onClick={toggleCreateModal} // Call toggleCreateModal to open the create modal
                                                >
                                                    <i className="mdi mdi-plus me-1"></i> Add New Key API
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">
                                                            <div className="font-size-16 form-check">
                                                                <label className="form-check-label">
                                                                    <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">ID</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">API Name</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">API Key</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">IPs</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Description</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Created At</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Posted By</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Action</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Table rows */}
                                                {
                                                    apiKeys.map(apiKey => (
                                                        <tr key={apiKey.id}>
                                                            <td>
                                                                <div className="cursor-pointer select-none">
                                                                    <div className="font-size-16 form-check">
                                                                        <label className="form-check-label">
                                                                            <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{apiKey.id}</td>
                                                            <td>{apiKey.apiName}</td>
                                                            <td>{apiKey.apiKey}</td>
                                                            <td>{apiKey.ip}</td>
                                                            <td>{apiKey.apiDescription}</td>
                                                            <td>{apiKey.createdAt}</td>
                                                            <td>{apiKey.postedBy}</td>

                                                            <td className="justify-content-evenly d-flex">
                                                                <Link
                                                                    className="btn btn-sm btn-soft-info"
                                                                    onClick={() => {
                                                                        handleEditClick(apiKey);
                                                                        toggleEditModal();
                                                                    }}
                                                                >
                                                                    <i className="mdi mdi-pencil-outline font-size-18" id="edittooltip" />
                                                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                                                        Edit
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                                <Link
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={() => handleDeleteClick(apiKey)}
                                                                >
                                                                    <i className="mdi mdi-delete-outline font-size-18" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                        Delete
                                                                    </UncontrolledTooltip>
                                                                </Link>

                                                                <Modal size="sm" isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
                                                                    <div className="modal-content">
                                                                        <ModalBody className="px-4 py-5 text-center">
                                                                            <button type="button" onClick={handleDeleteApiKey} className="btn-close position-absolute end-0 top-0 m-3"></button>
                                                                            <div className="avatar-sm mb-4 mx-auto">
                                                                                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                                                                    <i className="mdi mdi-trash-can-outline"></i>
                                                                                </div>
                                                                            </div>
                                                                            <p className="text-muted font-size-16 mb-4">Are you sure you want to permanently delete this Contact Group detail?</p>

                                                                            <div className="hstack gap-2 justify-content-center mb-0">
                                                                                <button type="button" className="btn btn-danger" onClick={handleDeleteApiKey}>Delete Now</button>
                                                                                <button type="button" className="btn btn-secondary" onClick={() => setDeleteModal(false)}>Close</button>
                                                                            </div>
                                                                        </ModalBody>
                                                                    </div>
                                                                </Modal>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody >
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info">Showing 10 of 26 Results</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled">
                                                        <a className="page-link" href="/ecommerce-apiKeys">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active"><a className="page-link" href="/ecommerce-apiKeys">1</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-apiKeys">2</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-apiKeys">3</a></li>
                                                    <li className="paginate_button page-item next "><a className="page-link" href="/ecommerce-apiKeys"><i className="mdi mdi-chevron-right"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewApiKey