import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table
} from "reactstrap";

function ActivityLogs() {
    document.title = "Activity Logs Reports";

    const [orders, setOrders] = useState([
        {
            id: 1,
            userName: 'iisol_testing',
            status: 'Activity Log',
            description: 'Created Activity Log Page',
            date_time: 'April 1st,2024',
        }
    ]);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Activity Logs" />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">User Name</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Status</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Decsription</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Activity Data & Time</div>
                                                    </th>                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Table rows */}
                                                {
                                                    orders.map(order => (
                                                        <tr key={order.id}>
                                                            <td>{order.userName}</td>
                                                            <td>{order.status}</td>
                                                            <td style={{ whiteSpace: 'normal' }}>{order.description}</td>
                                                            <td>{order.date_time}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody >
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info">Showing 10 of 26 Results</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled">
                                                        <a className="page-link" href="/ecommerce-orders">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active"><a className="page-link" href="/ecommerce-orders">1</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">2</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">3</a></li>
                                                    <li className="paginate_button page-item next "><a className="page-link" href="/ecommerce-orders"><i className="mdi mdi-chevron-right"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ActivityLogs