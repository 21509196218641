import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
} from "reactstrap";
import { ToastProvider, useToasts } from 'react-toast-notifications'; // Import ToastProvider
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";

function AddApiKey() {
    document.title = "Add Api Key";

    const [messageValue, setMessageValue] = useState('');
    const [selectedMasking, setSelectedMasking] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [optionMasking] = useState([
        { value: "masking1", label: "Masking 1" },
        { value: "masking2", label: "Masking 2" },
        { value: "masking3", label: "Masking 3" },
    ]);

    const [optionTemplate] = useState([
        { value: "template1", label: "Template 1" },
        { value: "template2", label: "Template 2" },
        { value: "template3", label: "Template 3" },
    ]);

    const [smsCounter, setSmsCounter] = useState({
        encoding: "",
        length: 0,
        per_message: 0,
        remaining: 0,
        messages: 0
    });

    const [contacts, setContacts] = useState("");

    const contactHandleChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9,\s]/g, '');
        const formattedValue = sanitizedValue.replace(/\s+/g, ',');

        if (inputValue !== sanitizedValue) {
            addToast('Phone numbers should only contain numeric characters.', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000
            });
        }
        const truncatedValue = formattedValue.slice(0, 100);
        setContacts(truncatedValue);
    };

    const countContactLength = (value) => {
        const contactsArray = value.split(',').filter(contact => contact.trim() !== '');
        let totalCount = 0;

        contactsArray.forEach(contact => {
            const digitCount = contact.replace(/[^0-9]/g, '').length;
            if ((digitCount === 11 && contact.startsWith("0")) || (digitCount === 12 && contact.startsWith("92"))) {
                totalCount += 1;
            }
        });

        return totalCount;
    };

    const { addToast } = useToasts();

    // Function to display a toast message
    const showToast = () => {
        addToast('This is a toast message', { appearance: 'success' });
    };

    useEffect(() => {
        function countSms(text) {
            const SmsCounter = {
                GSM_7BIT: 'GSM_7BIT',
                GSM_7BIT_EX: 'GSM_7BIT_EX',
                UTF16: 'UTF16',
                messageLength: {
                    GSM_7BIT: 160,
                    GSM_7BIT_EX: 160,
                    UTF16: 70
                },
                multiMessageLength: {
                    GSM_7BIT: 153,
                    GSM_7BIT_EX: 153,
                    UTF16: 67
                },
                count: function (text) {
                    let encoding = this.detectEncoding(text);
                    let length = text.length;
                    let per_message = this.messageLength[encoding];
                    if (length > per_message) {
                        per_message = this.multiMessageLength[encoding];
                    }
                    let messages = Math.ceil(length / per_message);
                    let remaining = (per_message * messages) - length;
                    return {
                        encoding: encoding,
                        length: length,
                        per_message: per_message,
                        remaining: remaining,
                        messages: messages
                    };
                },
                detectEncoding: function (text) {
                    // Use try-catch to handle exceptions
                    try {
                        if (text.match(/[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]+/gi)) {
                            return this.GSM_7BIT;
                        } else if (text.match(/[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\^{}\\\[\]~|€]+/gi)) {
                            return this.GSM_7BIT_EX;
                        } else {
                            return this.UTF16;
                        }
                    } catch (error) {
                        console.error("Error detecting encoding:", error);
                        return this.UTF16; // Default to UTF16 encoding in case of an error
                    }
                }
            };
            const count = SmsCounter.count(text);
            return count;
        }

        setSmsCounter(countSms(messageValue));
    }, [messageValue]);


    useEffect(() => {
        // Update maxlength attribute based on SMS encoding
        const updateMaxLength = () => {
            const textarea = document.getElementById("create-message");
            if (!textarea) return;

            switch (smsCounter.encoding) {
                case "GSM_7BIT":
                    textarea.setAttribute("maxlength", "765");
                    break;
                case "GSM_7BIT_EX":
                    textarea.setAttribute("maxlength", "763");
                    break;
                case "UTF16":
                    textarea.setAttribute("maxlength", "335");
                    break;
                default:
                    textarea.setAttribute("maxlength", "765");
                    break;
            }
        };

        updateMaxLength();
    }, [smsCounter.encoding]);

    const handleSelectMasking = (selectedOption) => {
        setSelectedMasking(selectedOption);
    };

    const handleSelectTemplate = (selectedOption) => {
        setSelectedTemplate(selectedOption);
    };

    const handleChange = (e) => {
        setMessageValue(e.target.value)
    }

    return (
        <ToastProvider>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Sent SMS" breadcrumbItem="Add Api Key" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Add Api Key</CardTitle>
                                        <Col lg={8}>
                                            <p className="card-title-desc">
                                                Add Api Key is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                            </p>
                                            {/* <Button onClick={showToast}>Show Toast</Button> */}
                                        </Col>
                                        <Form className="outer-repeater">
                                            <Row>
                                                <Col lg={12}>
                                                    <div data-repeater-list="outer-group" className="outer">
                                                        <div data-repeater-item className="outer">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Key Name : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Key Description : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key Description"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <div className="mb-3">
                                                                <Label htmlFor="formmessage">For multiples separate each IP with a comma:</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    id="formmessage"
                                                                    className="form-control"
                                                                    rows="10"
                                                                    placeholder="192.168.11.100, 192.168.12.100"
                                                                />
                                                                <p>For multiple entries, please separate each IP with a comma.</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end gap-2">
                                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                            <button type="button" className="btn btn-secondary w-md">Reset</button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </ToastProvider>
    )
}

const AddApiKeyWithToastProvider = () => (
    <ToastProvider>
        <AddApiKey />
    </ToastProvider>
);

export default AddApiKeyWithToastProvider;
