import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Form,
    ModalFooter
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import ErrorModal from "components/MyComponents/ErrorModal";
import SuccessModal from "components/MyComponents/SuccessModal";
import TableContainer from "components/Common/TableContainer";


function ViewContacts() {
    document.title = "Manage Contact Numbers";

    const [modalData, setModalData] = useState({
        id: '',
        Name: '',
        Cell_No: '',
        Group_Name: '',
        user_id: '',
        created_it: ''
    });
    const [orders, setOrders] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await fetch(`${BASE_URL}/contactgroups`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch groups');
                }
                const data = await response.json();
                const options = data.map(group => ({ value: group.id, label: group.GroupName }));
                setGroupOptions(options);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []);

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const handleEditClick = (rowData) => {
        const { id, Name, Cell_No, Contact_Email, Group_Name, user_id, created_it } = rowData.original;
        setModalData({
            id,
            Name,
            Cell_No,
            Contact_Email,
            Group_Name,
            user_id,
            created_it
        });
        toggleEditModal();

        console.log('Editing Row Data:', rowData);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'Group_Name') {
            const selectedGroup = groupOptions.find(group => group.label === value);
            setModalData(prevData => ({
                ...prevData,
                [name]: selectedGroup ? selectedGroup.value : value
            }));
        } else {
            setModalData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const updateFetchContacts = async () => {
        try {
            const response = await fetch(`${BASE_URL}/contacts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const updatedData = data.map(item => ({
                ...item,
                Group_Name: groupOptions.find(option => option.value === item.Group_Name)?.label || item.Group_Name
            }));
            setOrders(updatedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const id = modalData.id;
            console.log(id);
            const response = await fetch(`${BASE_URL}/contacts/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(modalData),

            });
            if (response.ok) {
                const data = await response.json();

                updateFetchContacts();
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === modalData.id ? { ...order, ...modalData } : order));
                console.log('Modal Data:', modalData);
                setSuccessMessage(data.message);
                setErrorMessage(data.errors);
                toggleEditModal();
                setErrorModalOpen(false);
                setSuccessModalOpen(true);
            } else {
                const errorData = await response.json();
                console.error(errorData.error);
                setErrorMessage('Error updating contact: ' + errorData.error);
                setSuccessMessage('');
                setErrorModalOpen(true);
                setSuccessModalOpen(false);
            }
        } catch (error) {
            console.error('Fetch request error:', error);
        }
        toggleEditModal();
    };

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch(`${BASE_URL}/contacts`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                const updatedData = data.map(item => ({
                    ...item,
                    user_id: item.name,
                    Group_Name: groupOptions.find(option => option.value === item.Group_Name)?.label || item.Group_Name
                }));
                setOrders(updatedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchContacts();
    }, [groupOptions]);

    const handleDeleteClick = (rowData) => {
        if (rowData.original && rowData.original.id) {
            setEditingRowData(rowData);
            toggleDeleteModal(true);
            console.log(rowData);
        } else {
            console.error('Row data is null or id is not defined');
        }
    };

    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.original.id;
            console.log(id);
            const response = await fetch(`${BASE_URL}/contacts/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.original.id }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.id !== rowData.original.id);
                setOrders(updatedOrders);
                updateFetchContacts();
                setDeleteModal(false);
            } else {
                console.error('Failed to delete contact group');
            }
        } catch (error) {
            console.error('Error deleting contact group:', error);
        }
    };

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'id', disableFilters: true, },
        { Header: 'Name', accessor: 'Name', disableFilters: true, },
        { Header: 'Contact Number', accessor: 'Cell_No', disableFilters: true, },
        { Header: 'Contact Email', accessor: 'Contact_Email', disableFilters: true, },
        { Header: 'Group', accessor: 'GroupName', disableFilters: true, },
        { Header: 'Posted By', accessor: 'user_id', disableFilters: true, },
        { Header: 'Created At', accessor: 'created_it', disableFilters: true, },
    ], []);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Contacts" breadcrumbItem="Manage Contacts" />
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            Edit Contacts
                        </ModalHeader>
                        <ModalBody>
                            <Form>

                                <Label > Name </Label>
                                <Input value={modalData.Name} name="Name" onChange={handleInputChange} />

                                <Label>Contact Number</Label>
                                <Input value={modalData.Cell_No} name="Cell_No" onChange={handleInputChange} />

                                <Label>Contact Email</Label>
                                <Input value={modalData.Contact_Email} name="Contact_Email" onChange={handleInputChange} />

                                <div className="mb-3">
                                    <Label>Select Group</Label>
                                    <select name="Group_Name" className="form-control" value={modalData.Group_Name} onChange={handleInputChange}>
                                        {groupOptions.map((group, index) => (
                                            <option key={index} value={group.value}>
                                                {group.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={handleSubmit}>Update Value</Button>
                        </ModalFooter>
                    </Modal>
                    <SuccessModal
                        isOpen={successModalOpen}
                        toggle={toggleSuccessModal}
                        successMessage={successMessage}
                    />
                    <ErrorModal
                        isOpen={errorModalOpen}
                        toggle={toggleErrorModal}
                        errorMessage={errorMessage}
                    />
                    <DeleteModal
                        backdrop="static"
                        isOpen={deleteModal}
                        toggle={() => setDeleteModal(false)}
                        handleDeleteOrder={handleDeleteOrder}
                        rowData={editingRowData}
                        value={'SMS Template'} 
                        />
                        
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-contact'}
                                        linkValue={'Add Contact'}
                                        customPageSize={2}
                                        isPagination={true}
                                        isActions={true}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewContacts