import React from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Button } from "reactstrap";

function BalanceReport() {
    document.title = "Balance Report";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Balance Report" />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Details</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Networks</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Total Units</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/all-network'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        All Networks
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/Jazz'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        Jazz
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/Warid'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        Warid
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/Zong'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>                                                    </td>
                                                    <td>
                                                        Zong
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/Ufone'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        Ufone
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Button
                                                            className="btn btn-soft-info d-flex align-items-center"
                                                            to={'/Balnce-Report/Telenor'}>


                                                            View Details &nbsp;
                                                            <i className="mdi mdi-eye-outline font-size-18"></i>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        Telenor
                                                    </td>
                                                    <td>
                                                        0
                                                    </td>
                                                </tr>

                                            </tbody >
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BalanceReport