import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Col, Container, Row, Alert, Form, Label, Input, FormFeedback } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
// import logodark from "../../assets/images/logo-dark.png";
import logodark from "assets/images/favicon-logo.png";
import logolight from "../../assets/images/logo-light.png";
import user from "../../assets/images/users/avatar-1.jpg";
import CarouselPage from "./CarouselPage";
import axios from "axios";
import BASE_URL from "Config/config";

const LockScreen2 = (props) => {

  //meta title
  document.title = "Lock Screen 2 | Skote - React Admin & Dashboard Template";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const token = localStorage.getItem('token');
  const [csrfToken, setCsrfToken] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/csrf-token');
      const data = await response.json();
      setCsrfToken(data.csrf_token);
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const [userData, setUserData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user-data`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        }
      });
      setUserData(response.data.data);
      console.log('Data:', response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkPassword = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/check-password`, { password }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        }
      });
      console.log('Password Match:', response.data);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error checking password:', error);

    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard"
                        className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Lock screen</h5>
                        <p className="text-muted">
                          Enter your password to unlock the screen!
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={checkPassword}
                        >
                          {props.error && props.error ? (
                            <Alert color="danger">{props.error}</Alert>
                          ) : null}

                          {userData && (
                            <div className="user-thumb text-center mb-4">
                              <img
                                src={user}
                                className="rounded-circle img-thumbnail avatar-md"
                                alt="thumbnail"
                              />
                              <h5 className="font-size-15 mt-3">{userData.name}</h5>
                            </div>
                          )}

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div className="d-flex">
                              <Input
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="Enter Password"
                                value={password}
                                onChange={handlePasswordChange}
                              />
                              <button
                                className="btn btn-light"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                <i className={`mdi ${showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}></i>
                              </button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>


                          <div className="text-end">
                            <button className="btn btn-primary w-md" type="button" onClick={checkPassword} > Unlock </button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Not you ? return{" "}
                            <Link
                              to="pages-register-2"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©{" "}
                        {new Date().getFullYear()}{" "}
                        Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

LockScreen2.propTypes = {
  error: PropTypes.any,
};

export default LockScreen2;
