import React from 'react'
import { Alert, Card, CardBody, Container } from 'reactstrap'

function WaridNetork() {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Card>
                        <CardBody>
                            <Alert color="info">
                                <h4 className="alert-heading">Warid Network</h4>
                                <p>
                                    No Record Found!
                                </p>
                            </Alert>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default WaridNetork