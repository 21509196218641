import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from "components/Common/TableContainer";
import BASE_URL from "Config/config";

function PersonalizedSmsReport() {
    document.title = "Perosonalized SMS Reports";

    const [orders, setOrders] = useState([]);

    const token = localStorage.getItem('token')
    
    useEffect(() => {
        const fetchPersonalizedSmsData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/personalize-sms`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching personlize sms data:', error);
            }
        };
        fetchPersonalizedSmsData();
    }, [])


    const handleDownloadHistory = (rowData) => {
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(`ID, Campaign Name, Masking, Total, Error, Sent, Remaining, Cost, User, Created At, Run Time\n${Object.values(rowData).join(',')}`);

        // Create anchor element for downloading
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        link.setAttribute("download", `personalize-sms history_${rowData.id}.csv`);
        document.body.appendChild(link);

        // Trigger download
        link.click();

        // Cleanup
        document.body.removeChild(link);
    };

    const columns = useMemo(() => [
        { Header: 'Id', accessor: 'CampaignID', disableFilters: true, },
        { Header: 'Campaign Name', accessor: 'campaign_name', disableFilters: true, },
        { Header: 'Masking', accessor: 'Mask', disableFilters: true, },
        { Header: 'Total SMS', accessor: 'totalSMS', disableFilters: true, },
        { Header: 'Error', accessor: 'totalErrorSMS', disableFilters: true, },
        { Header: 'Sent', accessor: 'totalSentSMS', disableFilters: true, },
        { Header: 'Remaining', accessor: 'totalErrorSMS', id: 'remaining' , disableFilters: true, },
        { Header: 'Cost', accessor: 'cost', disableFilters: true, },
        { Header: 'Status', accessor: 'status', disableFilters: true, },
        { Header: 'User', accessor: 'name', disableFilters: true, },
        { Header: 'Created At', accessor: 'created_it', disableFilters: true, },
        { Header: 'Run Time', accessor: 'created_it',id: 'run_time', disableFilters: true, },
    ], []);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Perosonalized SMS" />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-personalize-sms'}
                                        linkValue={'Add Personalize SMS'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActionHistory={true}
                                        handleDownloadData={handleDownloadHistory}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PersonalizedSmsReport