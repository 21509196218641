import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import BASE_URL from "Config/config";

function QuickSmsReport() {
    document.title = "Quick SMS Reports";
    const [modalData, setModalData] = useState({
        id: '',
        mobile_number: '',
        Mask: '',
        message: '',
        encoding: '',
        length: '',
        user_id: '',
        status: '',
        created_it: '',
        cost: ''
    });
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [editModal, setEditModal] = useState(false);

    const token = localStorage.getItem('token');

    const fetchQuickSms = async () => {
        try {
            const response = await fetch(`${BASE_URL}/quick-sms`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOrders(data);
        }
        catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    useEffect(() => {
        fetchQuickSms();
    }, []);

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };


    const handleViewData = (rowData) => {
        setEditingRowData(rowData.original);
        setModalData(rowData);
        toggleEditModal();
    };
    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'ID', disableFilters: true, },
        { Header: 'Contact Number', accessor: 'mobile_number', disableFilters: true, },
        { Header: 'Masking', accessor: 'Mask', disableFilters: true, },
        { Header: 'Message', accessor: 'message', disableFilters: true, },
        { Header: 'Unicode', accessor: 'encoding', disableFilters: true, },
        { Header: 'Length', accessor: 'length', disableFilters: true, },
        { Header: 'User', accessor: 'name', disableFilters: true, },
        { Header: 'Created At', accessor: 'created_it', disableFilters: true, },
        { Header: 'Status', accessor: 'status', disableFilters: true, },
        { Header: 'Cost', accessor: 'cost', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Quick SMS" />
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            {!!editingRowData ? "Report Detail" : "Add Order"}
                        </ModalHeader>
                        <ModalBody>

                            <h4 className="mb-2">
                                Report ID: <span className="text-primary">{modalData.ID}</span>
                            </h4>
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td>Contact Number</td>
                                            <td>{modalData.mobile_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Mask</td>
                                            <td>{modalData.Mask}</td>
                                        </tr>
                                        <tr>
                                            <td>Message</td>
                                            <td className="text-wrap">{modalData.message}</td>
                                        </tr>
                                        <tr>
                                            <td>Encoding</td>
                                            <td>{modalData.encoding}</td>
                                        </tr>
                                        <tr>
                                            <td>Length</td>
                                            <td>{modalData.length}</td>
                                        </tr>
                                        <tr>
                                            <td>User ID</td>
                                            <td>{modalData.user_id}</td>
                                        </tr>
                                        <tr>
                                            <td>Message Type:</td>
                                            <td>{modalData.sms_type}</td>
                                        </tr>
                                        <tr>
                                            <td>Report Description:</td>
                                            <td>{modalData.description}</td>
                                        </tr>
                                        <tr>
                                            <td>Report Status:</td>
                                            <td>{modalData.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Report Date:</td>
                                            <td>{modalData.created_it}</td>
                                        </tr>
                                        <tr>
                                            <td>Report Cost:</td>
                                            <td>{modalData.cost}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </ModalBody>
                        <div className="modal-footer">
                            <Button color="danger" onClick={handleViewData}>Done</Button>
                        </div>
                    </Modal>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/quick-sms'}
                                        linkValue={'Add Quick SMS'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={false}
                                        isAction={true}
                                        handleViewData={handleViewData}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default QuickSmsReport