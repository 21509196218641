import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import CustomStatusSelect from "components/MyComponents/CustomStatusSelect";
import TableContainer from "components/Common/TableContainer";

function ViewMaskingRequest() {
    document.title = "View Masking Request";

    const [modalData, setModalData] = useState({
        ID: '',
        Mask: '',
        Company_ID: '',
        created_it: '',
        status: '',
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);

    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const token = localStorage.getItem('token')

    const handleEditClick = (rowData) => {
        setModalData(rowData);
        toggleEditModal();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/masking-request`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [token]);

    const handleSaveModal = async () => {
        try {
            const id = modalData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/companies/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(modalData),
            });
            if (response.ok) {
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === modalData.ID ? { ...order, ...modalData } : order));
                toggleEditModal();

            } else {
                console.error('Failed to update Company data');
            }
        } catch (error) {
            console.error('Error updating Company data:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData && rowData.id) {
            setEditingRowData(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };


    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.ID;
            console.log(id);

            const response = await fetch(`${BASE_URL}/companies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.ID }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.ID !== rowData.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
            } else {
                console.error('Failed to delete Company data');
            }
        } catch (error) {
            console.error('Error deleting Company data:', error);
        }
    };

    const options = [
        { value: '1', label: 'Active', icon: <i className="bx bx-check-circle text-success"></i> },
        { value: '2', label: 'Inactive', icon: <i className="bx bx-x-circle text-danger"></i> }
    ];

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'MaskingRequest_ID', disableFilters: true, },
        { Header: 'Masking Name', accessor: 'masking_name', disableFilters: true, },
        { Header: 'Serivce Provider Name', accessor: 'service_provider_name', disableFilters: true, },
        { Header: 'Send Date', accessor: 'MaskingRequest_SendDate', disableFilters: true, },
        { Header: 'Masking Status', accessor: 'MaskingRequest_Status', disableFilters: true, },
        { Header: 'Status Date', accessor: 'MaskingRequest_StatusDate', disableFilters: true, },
        { Header: 'Created Time', accessor: 'MaskingRequest_Update_Date', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Masking" breadcrumbItem="Manage Masking Request" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Company </ModalHeader>
                        <ModalBody>

                            <Label>Company Name</Label>
                            <Input value={modalData.name} name="name" className="mb-3" onChange={handleInputChange} />

                            <Label>Company Phone</Label>
                            <Input value={modalData.phone_number} name="phone_number" className="mb-3" onChange={handleInputChange} />

                            <Label>Company Email</Label>
                            <Input value={modalData.email} name="email" className="mb-3" onChange={handleInputChange} />

                            <Label>Company Website</Label>
                            <Input value={modalData.website} name="website" className="mb-3" onChange={handleInputChange} />

                            <Label>NTN</Label>
                            <Input value={modalData.NTN} name="NTN" className="mb-3" onChange={handleInputChange} />

                            <Label>Company Logo</Label>
                            <Input value={modalData.logo} name="logo" className="mb-3" onChange={handleInputChange} />

                            <div className="modal-footer">
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-masking-request'}
                                        linkValue={'Add Masking Request'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewMaskingRequest