import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {

  const [app, setapp] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }


  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    {props.t("Dashboard")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="mdi mdi-comment-edit-outline me-2 font-size-20"></i>
                    {props.t("SMS Templates")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/add-template" className="dropdown-item">
                      {props.t("Add SMS Template")}
                    </Link>
                    <Link to="/view-sms-template" className="dropdown-item">
                      {props.t("View My Template")}
                    </Link>
                    <Link to="/view-builtin-template" className="dropdown-item">
                      {props.t("View Builtin Template")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="mdi mdi-comment-multiple-outline me-2 font-size-20"></i>
                    {props.t("Sent SMS")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/quick-sms" className="dropdown-item">
                      {props.t("Quick SMS")}
                    </Link>
                    <Link to="/add-sms-campaign" className="dropdown-item">
                      {props.t("Campaign SMS")}
                    </Link>
                    <Link to="/add-personalize-sms" className="dropdown-item">
                      {props.t("Personalized SMS")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="bx bx-crosshair me-2 font-size-20"></i>
                    {props.t("Bad Words")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/add-badwords" className="dropdown-item">
                      {props.t("Add Badword")}
                    </Link>
                    <Link to="/view-badwords-key" className="dropdown-item">
                      {props.t("View Badword")}
                    </Link>
                    <Link to="/view-badwords-group" className="dropdown-item">
                      {props.t("Manage Badword Groups")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="bx bxs-user-detail me-2 font-size-20"></i>
                    {props.t("Contacts")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/add-contact" className="dropdown-item">
                      {props.t("Add Contacts")}
                    </Link>
                    <Link to="/contacts" className="dropdown-item">
                      {props.t("Manage Contact")}
                    </Link>
                    <Link to="/contact-groups" className="dropdown-item">
                      {props.t("Manage Contact Groups")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="bx bxs-report me-2 font-size-20"></i>
                    {props.t("Reports")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/Reports/quick-sms" className="dropdown-item">
                      {props.t("Quick SMS")}
                    </Link>
                    <Link to="/Reports/bulk-sms" className="dropdown-item">
                      {props.t("Bulk SMS")}
                    </Link>
                    <Link to="/Reports/campaign-sms" className="dropdown-item">
                      {props.t("Campaign SMS")}
                    </Link>
                    <Link to="/Reports/personalized-sms" className="dropdown-item">
                      {props.t("Personalized SMS")}
                    </Link>
                    <Link to="/Reports/api-sms" className="dropdown-item">
                      {props.t("API SMS")}
                    </Link>
                    <Link to="/Reports/activity-logs" className="dropdown-item">
                      {props.t("Logs Report")}
                    </Link>
                    <Link to="/Reports/balance-reports" className="dropdown-item">
                      {props.t("Balance Report")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none align-items-center d-flex"
                  >
                    <i className="mdi mdi-file-key-outline me-2 font-size-20"></i>
                    {props.t("Key API")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="/add-api-key" className="dropdown-item">
                      {props.t("New Key API")}
                    </Link>
                    <Link to="/view-api-key" className="dropdown-item">
                      {props.t("View Key API")}
                    </Link>
                    <Link to="/api-key-docs" className="dropdown-item">
                      {props.t("View Key Docs")}
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
