import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";

function AddMaskingServiceProvider() {
    document.title = "Masking Service Provider";

    const [maskingServiceProvider, setMaskingServiceProvider] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const token = localStorage.getItem('token');

    const handleReset = () => {
        setMessageValue('');
        setBadwordGroupValue('');
        setSuccessMessage('');
        setErrorMessage('');
    };

    useEffect(() => {
        async function fetchCsrfToken() {
            try {
                const response = await fetch('http://127.0.0.1:8000/csrf-token');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.csrf_token);
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        }

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/service-provider`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    MaskingServiceProvider_Name: maskingServiceProvider,
                })
            });
            const data = await response.json(); // Use json() instead of text() if expecting JSON response
            if (response.ok) {
                setSuccessMessage('Serivce Provider added successfully');
                setMessageValue('');
                setSuccessModalOpen(true);
                setErrorModalOpen(false);
            } else {
                setErrorMessage(data.message || 'Unknown error');
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting form:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Masking" breadcrumbItem="Add Masking Service Provider" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <CardTitle className="h4">Add Masking Service Provider</CardTitle>
                                            <Col lg={12}>
                                                <p className="card-title-desc">
                                                    Masking is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                </p>
                                            </Col>
                                            <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div data-repeater-list="outer-group" className="outer">
                                                            <Row className="justify-content-center">
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Masking Service Provider Name : </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Masking Service Provider Name"
                                                                            value={maskingServiceProvider}
                                                                            onChange={(e) => setMaskingServiceProvider(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="d-flex justify-content-end gap-2 mt-4">
                                                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <SuccessModal
                    isOpen={successModalOpen}
                    toggle={toggleSuccessModal}
                    successMessage={successMessage}
                />
                <ErrorModal
                    isOpen={errorModalOpen}
                    toggle={toggleErrorModal}
                    errorMessage={errorMessage}
                />
            </div>
        </React.Fragment>
    )
}

export default AddMaskingServiceProvider;
