import React from "react";
import { Navigate } from "react-router-dom";

// MyImpoorts 
import Login from "../pages/Authentication/UserLogin";
import QuickSms from "pages/Forms/QuickSms";
import AddTemplate from "../pages/Forms/AddTemplate"
import AddCampaign from "pages/Forms/AddCampaign";
import AddPersonalizedSms from '../pages/Forms/PersonalizeSms';
import ViewSmsTemplate from "pages/Ecommerce/EcommerceOrders/viewSmsTemplate";
import ViewContacts from "pages/Ecommerce/EcommerceOrders/viewContacts";
import ViewContactGroups from "pages/Ecommerce/EcommerceOrders/ViewContactGroups";
import QuickSmsReport from "pages/Ecommerce/EcommerceOrders/QuickSmsReport";
import BulkSmsReport from "pages/Ecommerce/EcommerceOrders/BulkSmsReport";
import CampaignSmsReport from "pages/Ecommerce/EcommerceOrders/CampaignSmsReport";
import PersonalizedSmsReport from "pages/Ecommerce/EcommerceOrders/PersonalizedSmsReport";
import ActivityLogs from "pages/Ecommerce/EcommerceOrders/ActivityLogs";
import ApiSmsReport from "pages/Ecommerce/EcommerceOrders/ApiSmsReport";
import AddApiKeyWithToastProvider from "pages/Forms/AddApiKey";
import ViewApiKey from "pages/Ecommerce/EcommerceOrders/ViewApiKey";
import KeyApiDocs from "pages/Ecommerce/EcommerceOrders/KeyApiDocs";
import BalanceReport from "pages/Ecommerce/EcommerceOrders/BalanceReport";
import AllNetwork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/AllNetwork";
import JazzNetork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/JazzNetork";
import WaridNetork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/WaridNetwork";
import ZongNetork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/ZongNetwork";
import UfoneNetork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/UfoneNetwork";
import TelenorNetork from "pages/Ecommerce/EcommerceOrders/BalanceReportDetail/TelenorNetwork";
import AddTemplateWithToastProvider from "../pages/Forms/AddTemplate";
import AddBadwordsWithToastProvider from "pages/Forms/AddBadwords";
import ViewBadwordsKeys from "pages/Ecommerce/EcommerceOrders/viewBadwordsKeys";
import AddContacts from "pages/Forms/AddContact";
import ViewBadwordGroups from "pages/Ecommerce/EcommerceOrders/ViewBadwordGroups";
import AddBuiltinTemplate from "pages/Forms/AddBuiltinTemplate";
import ViewBuiltinSmsTemplate from "pages/Ecommerce/EcommerceOrders/ViewBuiltinSmsTemplate";
import Dashboard from "pages/Dashboard";
import AddCompany from "pages/Forms/AddCompany";
import ViewCompanyDetails from "pages/Ecommerce/EcommerceOrders/ViewCompanyDetails";
import AddCompanyUsers from "pages/Forms/AddCompanyUsers";
import AddMasking from "pages/Forms/AddMasking";
import AddMaskingAllocation from "pages/Forms/AddMaskingAllocation";
import AddIP from "pages/Forms/AddIP";
import ViewMaskingDirectory from "pages/Ecommerce/EcommerceOrders/ViewMaskingDirectory";
import ViewMaskingRequest from "pages/Ecommerce/EcommerceOrders/ViewMaskingRequest";
import ViewCompanyUsers from "pages/Ecommerce/EcommerceOrders/ViewCompanuUsers";
import AddMaskingRequest from "pages/Forms/AddMaskingRequest";
import ViewMaskingServiceProvider from "pages/Ecommerce/EcommerceOrders/ViewMaskingServiceProvider";
import AddMaskingServiceProvider from "pages/Forms/AddMaskingServiceProvider";

import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import ReCharts from "../pages/Charts/ReCharts";
import ViewBuiltinTemplate from "pages/Ecommerce/EcommerceOrders/ViewBuiltinTemplate";
import LockScreen from "pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "pages/AuthenticationInner/auth-lock-screen-2";

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartist-charts", component: <ChartistChart /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },
  // myRoutes
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/add-masking", component: <AddMasking /> },
  { path: "/add-masking-service-provider", component: <AddMaskingServiceProvider /> },
  { path: "/view-masking-service-provider", component: <ViewMaskingServiceProvider /> },
  { path: "/view-masking-directory", component: <ViewMaskingDirectory /> },
  { path: "/view-masking-request", component: <ViewMaskingRequest /> },
  { path: "/add-masking-request", component: <AddMaskingRequest /> },
  { path: "/add-masking-allocation", component: <AddMaskingAllocation /> },
  { path: "/add-company", component: <AddCompany /> },
  { path: "/view-company-details", component: <ViewCompanyDetails /> },
  { path: "/add-company-users", component: <AddCompanyUsers /> },
  { path: "/view-company-users-details", component: <ViewCompanyUsers /> },
  { path: "/add-ip", component: <AddIP /> },
  { path: "/add-builtin-template", component: <AddBuiltinTemplate /> },
  { path: "/add-template", component: <AddTemplateWithToastProvider /> },
  { path: "/quick-sms", component: <QuickSms /> },
  { path: "/add-sms-campaign", component: <AddCampaign /> },
  { path: "/add-personalize-sms", component: <AddPersonalizedSms /> },
  { path: "/view-sms-template", component: <ViewSmsTemplate /> },
  { path: "/view-builtin-sms-template", component: <ViewBuiltinSmsTemplate /> },
  { path: "/view-builtin-template", component: <ViewBuiltinTemplate /> },
  { path: "/add-contact", component: <AddContacts /> },
  { path: "/contacts", component: <ViewContacts /> },
  { path: "/contact-groups", component: <ViewContactGroups /> },
  { path: "/add-badwords", component: <AddBadwordsWithToastProvider /> },
  { path: "/view-badwords-key", component: <ViewBadwordsKeys /> },
  { path: "/view-badwords-group", component: <ViewBadwordGroups /> },
  { path: "/Reports/quick-sms", component: <QuickSmsReport /> },
  { path: "/Reports/bulk-sms", component: <BulkSmsReport /> },
  { path: "/Reports/campaign-sms", component: <CampaignSmsReport /> },
  { path: "/Reports/personalized-sms", component: <PersonalizedSmsReport /> },
  { path: "/Reports/activity-logs", component: <ActivityLogs /> },
  { path: "/Reports/api-sms", component: <ApiSmsReport /> },
  { path: "/Reports/balance-reports", component: <BalanceReport /> },
  { path: "/add-api-key", component: <AddApiKeyWithToastProvider /> },
  { path: "/view-api-key", component: <ViewApiKey /> },
  { path: "/api-key-docs", component: <KeyApiDocs /> },
  { path: "/Balnce-Report/all-network", component: <AllNetwork /> },
  { path: "/Balnce-Report/Jazz", component: <JazzNetork /> },
  { path: "/Balnce-Report/Warid", component: <WaridNetork /> },
  { path: "/Balnce-Report/Zong", component: <ZongNetork /> },
  { path: "/Balnce-Report/Ufone", component: <UfoneNetork /> },
  { path: "/Balnce-Report/Telenor", component: <TelenorNetork /> },
];

const publicRoutes = [

  { path: "/login", component: <Login /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
];

export { authProtectedRoutes, publicRoutes };
