import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import TableContainer from "components/Common/TableContainer";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";

function ViewContactGroups() {
    document.title = "Manage Contact Groups";

    const [modalData, setModalData] = useState({
        ID: '',
        GroupName: '',
        GroupDescription: '',
        total: '',
        user_id: '',
        created_it: ''
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [edtingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [deleteButtonVisible, setDeleteButtonVisible] = useState(false);


    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchContactGroups();
    }, []);

    const fetchContactGroups = async () => {
        try {
            const response = await fetch(`${BASE_URL}/contactgroups`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch contact groups');
            }
            const data = await response.json();
            setOrders(data.map(contactGroup => ({
                ID: contactGroup.id,
                GroupName: contactGroup.GroupName,
                GroupDescription: contactGroup.GroupDescription,
                total: contactGroup.total,
                user_id: contactGroup.name,
                created_it: contactGroup.created_it
            })));
        } catch (error) {
            console.error('Error fetching contact groups:', error);
        }
    };

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const handleEditClick = (rowData) => {
        if (rowData.original && rowData.original.ID) {
            setModalData(rowData.original);
            toggleEditModal();
        } else {
            console.error("Row data or ID not found.");
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModalData(prevModalData => ({
            ...prevModalData,
            [name]: value
        }));
    };

    const updateContactGroupData = async () => {
        const response = await fetch(`${BASE_URL}/contactgroups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(modalData),
        });
        if (response.ok) {
            const data = await response.json();
            setOrders(data);
        } else {
            console.error('Failed to update contact group');
        }
    }

    const handleSaveModal = async () => {
        try {
            const id = modalData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/contactgroups/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(modalData),
            });
            if (response.ok) {
                const data = await response.json();
                updateContactGroupData();
                setSuccessModalOpen(true);
                setSuccessMessage(data.message)
                setErrorMessage(data.errors)
                setOrders(prevOrders =>
                    prevOrders.map(order =>
                        order.id === modalData.ID ? { ...order, ...modalData } : order
                    )
                );
                toggleEditModal();
            } else {
                const errorData = await response.json();
                console.error(errorData.error);
                setErrorMessage('Error updating contact: ' + errorData.error);
                setSuccessMessage('');
                setErrorModalOpen(true);
            }
        } catch (error) {

            console.error('Error updating contact group:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData.original && rowData.original.ID) {
            setEditingRowData(rowData);
            toggleDeleteModal(true);
            console.log(rowData);
        } else {
            console.error('Row data is null or id is not defined');
        }
    };

    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.original.ID;
            const response = await fetch(`${BASE_URL}/contactgroups/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.original.ID }),
            });
            if (response.ok) {
                const data = await response.json();
                const updatedOrders = orders.filter(order => order.id !== rowData.original.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
                setSuccessModalOpen(true);
                setSuccessMessage(data.message);
                setErrorMessage(data.errors);
                toggleEditModal();
            } else {
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
                setErrorMessage(data.message);
                setSuccessMessage(data.errors);
                console.error('Failed to delete contact group');
            }
        } catch (error) {
            console.error('Error deleting contact group:', error);
        }
    };

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'ID', disableFilters: true, },
        { Header: 'Group Name', accessor: 'GroupName', disableFilters: true, },
        { Header: 'Group Description', accessor: 'GroupDescription', disableFilters: true, },
        { Header: 'Total', accessor: 'total', disableFilters: true, },
        { Header: 'Posted By', accessor: 'user_id', disableFilters: true, },
        { Header: 'Created At', accessor: 'created_it', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Contacts" breadcrumbItem="Manage Contact Groups" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Contact Group</ModalHeader>
                        <ModalBody>
                            <Label>Group Name</Label>
                            <Input value={modalData.GroupName} name="GroupName" onChange={handleInputChange} />
                            <Label>Group Description</Label>
                            <Input value={modalData.GroupDescription} name="GroupDescription" onChange={handleInputChange} />
                            <div className="modal-footer">
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <SuccessModal
                        isOpen={successModalOpen}
                        toggle={toggleSuccessModal}
                        successMessage={successMessage}
                    />
                    <ErrorModal
                        isOpen={errorModalOpen}
                        toggle={toggleErrorModal}
                        errorMessage={errorMessage}
                    />
                    <DeleteModal
                        backdrop="static"
                        isOpen={deleteModal}
                        toggle={() => setDeleteModal(false)}
                        handleDeleteOrder={handleDeleteOrder}
                        rowData={edtingRowData}
                        value={'SMS Template'} />


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        linkURL={'/add-contact#2'}
                                        linkValue={'Add Contact Group'}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewContactGroups