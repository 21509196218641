import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
    CardText,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardSubtitle,
    Modal,
    InputGroup,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import Flatpickr from "react-flatpickr";
import './MobileStyling/mobileStyling.css'
import ContactModalTables from "components/MyComponents/ContactModalTables";
import ContactGroupModalTables from "components/MyComponents/ContactGroupModalTables";
import BASE_URL from "Config/config";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";
import axios from "axios";


function AddCampaign() {
    document.title = "Campaign SMS";

    const [activeTab1, setactiveTab1] = useState("1");
    const [messageValue, setMessageValue] = useState('');
    const [campaign_name, setCampaign_name] = useState('');
    const [selectedMasking, setSelectedMasking] = useState('');
    const [optionMasking, setOptionMasking] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [optionTemplate, setOptionTemplate] = useState([]);
    const [contactModal, setCotnactModal] = useState(false);
    const [contactGroupModal, setContactGroupModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [pick_date, setPickDate] = useState("");
    const location = useLocation();
    const [smsCounter, setSmsCounter] = useState({
        encoding: "",
        length: 0,
        per_message: 0,
        remaining: 0,
        messages: 0
    });

    const [contacts, setContacts] = useState("");
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    const token = localStorage.getItem('token');

    const hours = currentTime.getHours();
    const isPM = hours >= 12;
    const displayHours = isPM ? hours % 12 || 12 : hours;
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');
    const timeString = `${displayHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;

    const contactHandleChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^0-9,\s]/g, '');
        const formattedValue = sanitizedValue.replace(/\s+/g, ',');

        if (inputValue !== sanitizedValue) {
            alert('Phone numbers should only contain numeric characters.', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000
            });
        }
        const truncatedValue = formattedValue.slice(0, 100);
        setContacts(truncatedValue);
    };

    const countContactLength = (value) => {
        const contactsArray = value.split(',').filter(contact => contact.trim() !== '');
        let totalCount = 0;

        contactsArray.forEach(contact => {
            const digitCount = contact.replace(/[^0-9]/g, '').length;
            if ((digitCount === 11 && contact.startsWith("0")) || (digitCount === 12 && contact.startsWith("92"))) {
                totalCount += 1;
            }
        });

        return totalCount;
    };

    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${BASE_URL}/campaign-sms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    campaign_name: campaign_name,
                    message: messageValue,
                    Mask: selectedMasking,
                    encoding: smsCounter.encoding,
                    length: smsCounter.length,
                    messages: smsCounter.messages,
                    contact: contacts,
                })
            });
            if (!response.ok) {
                const data = await response.json();
                setErrorMessage(data.message);
                setErrorModalOpen(true);
                setSuccessModalOpen(false);
                return
            }

            const responseData = await response.json();
            setSuccessMessage(responseData.message);
            setSuccessModalOpen(true);
            setErrorModalOpen(false);

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || "Campaign created successfully!");
                setSuccessModalOpen(true);
            } else {
                setErrorMessage(data.message);
                console.log(data.message);
                setErrorModalOpen(true);
            }
        } catch (error) {
            console.error('Error adding campaign:', error);
        }
    }

    useEffect(() => {
        function countSms(text) {
            const SmsCounter = {
                GSM_7BIT: 'GSM_7BIT',
                GSM_7BIT_EX: 'GSM_7BIT_EX',
                UTF16: 'UTF16',
                messageLength: {
                    GSM_7BIT: 160,
                    GSM_7BIT_EX: 160,
                    UTF16: 70
                },
                multiMessageLength: {
                    GSM_7BIT: 153,
                    GSM_7BIT_EX: 153,
                    UTF16: 67
                },
                count: function (text) {
                    let encoding = this.detectEncoding(text);
                    let length = text.length;
                    let per_message = this.messageLength[encoding];
                    if (length > per_message) {
                        per_message = this.multiMessageLength[encoding];
                    }
                    let messages = Math.ceil(length / per_message);
                    let remaining = (per_message * messages) - length;
                    return {
                        encoding: encoding,
                        length: length,
                        per_message: per_message,
                        remaining: remaining,
                        messages: messages
                    };
                },
                detectEncoding: function (text) {

                    if (text.trim() === '') {
                        return ' ';
                    }
                    // Use try-catch to handle exceptions
                    try {
                        if (text.match(RegExp("^[" + this.gsm7bitChars + "]*$", "gi"))) {
                            return this.GSM_7BIT;
                        } else if (text.match(RegExp("^[" + this.gsm7bitChars + this.gsm7bitExChar + "]*$", "gi"))) {
                            return this.GSM_7BIT_EX;
                        } else {
                            return this.UTF16;
                        }
                    } catch (error) {
                        console.error("Error detecting encoding:", error);
                        return this.GSM_7BIT;
                    }
                },
                gsm7bitChars: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
                gsm7bitExChar: "\\^{}\\\\\\[~\\]|€"
            };

            const count = SmsCounter.count(text);
            return count;
        }


        setSmsCounter(countSms(messageValue));

    }, [messageValue]);

    useEffect(() => {
        const updateMaxLength = () => {
            const textarea = document.getElementById("create-message");
            if (!textarea) return;

            switch (smsCounter.encoding) {
                case "GSM_7BIT":
                    textarea.setAttribute("maxlength", "765");
                    break;
                case "GSM_7BIT_EX":
                    textarea.setAttribute("maxlength", "763");
                    break;
                case "UTF16":
                    textarea.setAttribute("maxlength", "335");
                    break;
                default:
                    textarea.setAttribute("maxlength", "765");
                    break;
            }
        };

        updateMaxLength();
    }, [smsCounter.encoding]);

    const handleTemplateChange = (selectedOption) => {
        setSelectedTemplate(selectedOption);
        setMessageValue(selectedOption.message);
    }

    useEffect(() => {
        if (location.state && location.state.selectedTemplateId && optionTemplate.length > 0) {
            const selectedId = location.state.selectedTemplateId;
            console.log('Selected ID:', selectedId); // Log the selected ID
            console.log('Option Templates:', optionTemplate); // Log the option templates
            const selectedTemplate = optionTemplate.find(option => option.value === selectedId);
            console.log('Selected Template:', selectedTemplate); // Log the selected template
            if (selectedTemplate) {
                setSelectedTemplate(selectedTemplate);
                setMessageValue(selectedTemplate.message);
            }
        }
    }, [location.state, optionTemplate]);

    const handleChange = (e) => {
        setMessageValue(e.target.value);
        setCampaign_name(e.target.value);
    }

    // tabs 
    const toggle = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const handleContactModal = () => setCotnactModal(!contactModal);
    const handleContactGroupModal = () => setContactGroupModal(!contactGroupModal);

    function handleRemoveFile(f){
        setSelectedFiles("");
        setContacts('');
        console.log(f.name);
    }
    // Upload File 
    function handleAcceptedFiles(files) {
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const lines = content.split(/\r?\n/);
                const phoneNumbersArray = [];

                lines.forEach(line => {
                    const trimmedLine = line.trim();
                    if (trimmedLine) {
                        const columns = trimmedLine.split(',');
                        const phoneNumber = columns[0].trim();
                        if (phoneNumber) {
                            phoneNumbersArray.push('0' + phoneNumber);
                        }
                    }
                });

                setContacts(prevContent => `${prevContent}${phoneNumbersArray.join(',')}`);
            };
            reader.readAsText(file);
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            });
        });

        setSelectedFiles(files);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const [badWords, setBadWords] = useState([]);

    const fetchBadWords = async () => {
        try {
            const TOKEN = localStorage.getItem('token')
            const response = await fetch(`${BASE_URL}/view-badwords`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBadWords(data);
        } catch (error) {
            console.error("Error fetching bad words:", error);
        }
    }

    const fetchMasking = async () => {
        try {
            const response = await fetch(`${BASE_URL}/masking`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const options = data.map(masking => ({
                value: masking.ID,
                label: masking.Mask
            }));
            setOptionMasking(options);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    const fetchSmsTemplates = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/view-template`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = response.data;
            console.log('API Response:', data);
            const options = data.map(template => ({
                value: template.ID,
                label: template.template_name,
                message: template.template_message
            }));
            console.log('Mapped Options:', options);
            setOptionTemplate(options);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    useEffect(() => {
        fetchBadWords();
        fetchMasking();
        fetchSmsTemplates();
    }, []);

    const handleSelectMasking = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the event
        setSelectedMasking(selectedValue);         // Update state
        console.log(selectedValue);                // Log the selected value
    };

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    return (
        <ToastProvider>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <SuccessModal
                            isOpen={successModalOpen}
                            toggle={toggleSuccessModal}
                            successMessage={successMessage}
                        />
                        <ErrorModal
                            isOpen={errorModalOpen}
                            toggle={toggleErrorModal}
                            errorMessage={errorMessage}
                        />
                        <Breadcrumbs title="Sent SMS" breadcrumbItem="Campaign SMS" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Add Campaign SMS</CardTitle>
                                        <p className="card-title-desc">
                                            Add Campaign SMS is designed to simplify the process of sending short messages swiftly. With Add Campaign SMS, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                        </p>

                                        <hr style={{ border: '1px solid silver' }} />

                                        <Form className="outer-repeater" onSubmit={handleSubmit}>
                                            <CardBody>
                                                <div className="wizard clearfix">
                                                    <div className="content clearfix">
                                                        <TabContent activeTab={activeTab} className="body">
                                                            <TabPane tabId={1}>
                                                                <Form className="outer-repeater">
                                                                    <Row>
                                                                        <Col lg={5}>
                                                                            <div data-repeater-list="outer-group" className="outer">
                                                                                <div data-repeater-item className="outer">
                                                                                    <div className="mb-4">
                                                                                        <CardTitle className="h5">Empower Your SMS Campaign</CardTitle>
                                                                                    </div>
                                                                                    <Nav tabs className="navtab-bg nav-justified nav nav-pills">
                                                                                        <NavItem>
                                                                                            <NavLink
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className={classnames({
                                                                                                    active: activeTab1 === "1",
                                                                                                })}
                                                                                                onClick={() => {
                                                                                                    toggle("1");
                                                                                                }}
                                                                                            >
                                                                                                Contacts/Groups
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                        <NavItem>
                                                                                            <NavLink
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className={classnames({
                                                                                                    active: activeTab1 === "2",
                                                                                                })}
                                                                                                onClick={() => {
                                                                                                    toggle("2");
                                                                                                }}
                                                                                            >
                                                                                                Upload File
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Nav>

                                                                                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                                                                        <TabPane tabId="1">
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <CardText className="mb-0">
                                                                                                        <CardSubtitle className="h5 my-3">Efficient Contact Management Options</CardSubtitle>
                                                                                                        <p className="card-title-desc">
                                                                                                            Easily manage your contacts with our convenient options. Add numbers quickly for efficient communication management
                                                                                                        </p>
                                                                                                    </CardText>
                                                                                                    <div className="modal-btns d-flex gap-3">
                                                                                                        <Button type="button" onClick={handleContactModal} className="btn btn-danger ">
                                                                                                            Add Contact
                                                                                                        </Button>

                                                                                                        <Button type="button" onClick={handleContactGroupModal} className="btn btn-soft-danger waves-effect waves-light btn btn-secondary">
                                                                                                            Add Group
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </TabPane>
                                                                                        <TabPane tabId="2">
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <CardText className="mb-0">
                                                                                                        <CardSubtitle className="h5 my-3" >Simplify with Our Upload Feature</CardSubtitle>
                                                                                                        <p className="card-title-desc">
                                                                                                            Quickly add numbers for efficient communication and experience seamless organization with our user-friendly tools.
                                                                                                        </p>

                                                                                                    </CardText>
                                                                                                    <Form>
                                                                                                        <Label>Upload File</Label>
                                                                                                        <Dropzone
                                                                                                            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                                                                                                        >
                                                                                                            {({ getRootProps, getInputProps }) => (
                                                                                                                <div className="dropzone">
                                                                                                                    <div className="dz-message needsclick" {...getRootProps()}>
                                                                                                                        <input {...getInputProps()} />
                                                                                                                        <div className="mb-3">
                                                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                                                        </div>
                                                                                                                        <h5>Drop files here or click to upload.</h5>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Dropzone>
                                                                                                        <div className="dropzone-previews mt-3" id="file-previews">
                                                                                                            {selectedFiles.map((f, i) => (
                                                                                                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                                                                                                    <div className="p-2">
                                                                                                                        <Row className="align-items-center">
                                                                                                                            <Col className="col-auto">
                                                                                                                                <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <Link to="#" className="text-muted font-weight-bold">
                                                                                                                                    {f.name}
                                                                                                                                </Link>
                                                                                                                                <p className="mb-0">
                                                                                                                                    <strong>{f.size}</strong>
                                                                                                                                </p>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <button
                                                                                                                                    className="btn btn-soft-danger"
                                                                                                                                    onClick={() => handleRemoveFile()}
                                                                                                                                >
                                                                                                                                    <i className="mdi mdi-delete-outline font-size-13" id="deletetooltip" />
                                                                                                                                </button>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </div>
                                                                                                                </Card>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </Form>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </TabPane>
                                                                                    </TabContent>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={7}>
                                                                            <div data-repeater-list="outer-group" className="outer">
                                                                                <div data-repeater-item className="outer">
                                                                                    <div className="mb-4">
                                                                                        <Label>Add Campaign Name</Label>
                                                                                        <Input
                                                                                            type="text"
                                                                                            value={campaign_name}
                                                                                            onChange={handleChange}
                                                                                            id="campaign_name"
                                                                                            name="campaign_name"
                                                                                            className="form-control"
                                                                                            placeholder="Enter Your Campaign Name"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <Label htmlFor="formname">Select Masking : </Label>
                                                                                        <select name="Mask" className="form-select" value={selectedMasking} onChange={handleSelectMasking}>
                                                                                            <option value='' disabled defaultValue={'Select....'}>Select Masking</option>
                                                                                            {optionMasking.map((option) => (
                                                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="mb-3">
                                                                                        <Label htmlFor="formmessage">Add Contact Numbers :</Label>
                                                                                        <Input
                                                                                            type="textarea"
                                                                                            id="formmessage"
                                                                                            className="form-control"
                                                                                            rows="10"
                                                                                            name="contact"
                                                                                            placeholder="Add your Numbers"
                                                                                            value={contacts}
                                                                                            onChange={contactHandleChange}
                                                                                        />
                                                                                        <ul className="btn btn-secondary btn-soft-info d-flex justify-content-between list-unstyled rounded waves-effect waves-light my-3">
                                                                                            <li>
                                                                                                Contact : {countContactLength(contacts)}
                                                                                            </li>
                                                                                            <li>
                                                                                                Max Contact: 100
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <Col lg={8}>
                                                                        <div data-repeater-list="outer-group" className="outer">
                                                                            <div data-repeater-item className="outer">
                                                                                <div className="mb-3">
                                                                                    <Label>Choose SMS Template</Label>
                                                                                    <Select
                                                                                        value={selectedTemplate}
                                                                                        onChange={handleTemplateChange}
                                                                                        options={optionTemplate}
                                                                                        className="select2-selection"
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <div className="d-flex justify-content-between align-items-center mb-2">

                                                                                <Label htmlFor="message" className="mb-0">Create SMS Template</Label>
                                                                                <small className={`btn btn-sm btn-soft-${smsCounter.encoding === 'GSM_7BIT' ? 'info' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'primary' : 'danger'}`}>
                                                                                    {smsCounter.encoding === 'GSM_7BIT' ? 'MAX: 765' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'MAX: 755' : 'MAX: 355'} Characters ({smsCounter.encoding})
                                                                                </small>
                                                                            </div>
                                                                            <Input
                                                                                type="textarea"
                                                                                rows={10}
                                                                                id="create-message"
                                                                                name='message'
                                                                                // className={`form-control ${errors.smsTemplate ? 'is-invalid' : ''}`}
                                                                                className="form-control"
                                                                                placeholder="Enter Your Message"
                                                                                value={messageValue}
                                                                                onChange={handleChange}
                                                                                maxLength={smsCounter.encoding === "GSM_7BIT" ? 765 : smsCounter.encoding === "GSM_7BIT_EX" ? 763 : 335}

                                                                            />
                                                                            {/* {errors.smsTemplate && <div className="invalid-feedback">{errors.smsTemplate}</div>}
                                                                            {errors.messageValue && <div className="mt-1 text-danger" >{errors.messageValue}</div>} */}
                                                                        </div>

                                                                        <ul className=" btn btn-soft-danger waves-effect waves-light btn btn-secondary list-unstyled d-flex justify-content-between rounded">
                                                                            <li>Message length: {smsCounter.length}</li>
                                                                            <li>No. of Messages: {smsCounter.messages}</li>
                                                                        </ul>
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        <div className="phone-graphic">
                                                                            <div className="phone-case">
                                                                                <div className="phone-container-outer">
                                                                                    <div className="phone-container-inner">
                                                                                        <div className="phone-header">
                                                                                            <p className="phone-header-time">{timeString}</p>
                                                                                            <p className="phone-header-icons"><i className="mdi mdi-signal"></i> <i className="mdi mdi-wifi"></i> <i className="mdi mdi-battery"></i></p>
                                                                                            <div className="contact-image"> <i className="fas fa-user-circle" style={{ fontSize: '35px' }}></i> </div>
                                                                                            <p className="contact-phone">Connect Pulse</p>
                                                                                        </div>
                                                                                        <div className="phone-messages" style={{ height: '70%', overflowY: 'auto' }}>
                                                                                            <div className="message message-you">
                                                                                                <p> Create New Template </p>
                                                                                            </div>
                                                                                            <div className="message message-contact">
                                                                                                <p> {messageValue} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="phone-footer">
                                                                                            <div className="phone-footer-icons"><i className="mdi mdi-camera" style={{ fontSize: '23px' }}></i></div>
                                                                                            <div className="phone-footer-input">
                                                                                                <p>Text Message</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="actions clearfix">
                                                        <ul>
                                                            <li className={activeTab === 1 ? "next disabled" : null}>
                                                                {activeTab !== 1 && (
                                                                    <Button type='button' color="btn btn-success w-md" onClick={handleSubmit}>
                                                                        Submit
                                                                    </Button>)}
                                                            </li>
                                                            <li className={activeTab === 1 ? "previous disabled" : "previous"} >
                                                                <Link to="#" onClick={() => {
                                                                    toggleTab(activeTab - 1)
                                                                }} >
                                                                    Previous
                                                                </Link>
                                                            </li>
                                                            <li className={activeTab === 2 ? "next disabled" : null}>
                                                                {activeTab !== 2 && (
                                                                    <Link to="#" onClick={() => {
                                                                        toggleTab(activeTab + 1)
                                                                    }}>
                                                                        Next
                                                                    </Link>
                                                                )}
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <ContactModalTables
                        contactModal={contactModal}
                        handleContactModal={handleContactModal} // Pass the correct handler
                    />

                    <ContactGroupModalTables
                        contactGroupModal={contactGroupModal}
                        handleContactGroupModal={handleContactGroupModal} // Pass the correct handler
                    />

                    {/* <Modal isOpen={contactGroupModal} toggle={handleContactGroupModal} size="xl">
                        <ModalHeader>
                            Modal Heading
                        </ModalHeader>
                        <div className="modal-body">
                            <h5>Add Group</h5>
                            <p>
                                Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in, egestas
                                eget quam. Morbi leo risus, porta ac consectetur
                                ac, vestibulum at eros.
                            </p>
                        </div>
                        <ModalFooter>
                            <Button type="button" onClick={handleContactGroupModal} className="btn btn-secondary " >
                                Close
                            </Button>
                            <Button type="button" className="btn btn-primary " >
                                Save changes
                            </Button>
                        </ModalFooter>
                    </Modal> */}
                </div>
            </React.Fragment>
        </ToastProvider>
    )
}

export default AddCampaign