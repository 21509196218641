import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, InputGroup } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BASE_URL from "Config/config";
import classnames from "classnames"
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function AddCompanyUsers() {
    document.title = "Add Company Users";

    const [formData, setFormData] = useState({
        User_Name: '',
        User_Pass: '',
        Company_ID: '', // Updated field name
        User_Account_Expiry: '',
        User_Description: '',
        User_Cell: ['', '', '', ''],
        User_Email: ['', '', '', ''],
    });

    const [User_Name, setUser_Name] = useState('');
    const [User_Description, setUser_Description] = useState('');
    const [User_Pass, setUser_Pass] = useState('');
    const [User_Account_Expiry, setUser_Account_Expiry] = useState('');
    const [companies, setCompanies] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [pick_date, setPickDate] = useState("");
    const [csrfToken, setCsrfToken] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const token = localStorage.getItem('token');

    const fetchCompanies = async () => {
        try {
            const response = await fetch(`${BASE_URL}/companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setCompanies(data);
            } else {
                console.error('Failed to fetch company data');
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('User_Cell')) {
            const index = parseInt(name.match(/\[(\d+)\]/)[1]);
            setFormData({
                ...formData,
                User_Cell: formData.User_Cell.map((item, idx) => (idx === index ? value : item)),
            });
        } else if (name.includes('User_Email')) {
            const index = parseInt(name.match(/\[(\d+)\]/)[1]);
            setFormData({
                ...formData,
                User_Email: formData.User_Email.map((item, idx) => (idx === index ? value : item)),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (name.includes('User_Cell')) {
    //         const index = parseInt(name.match(/\[(\d+)\]/)[1]);
    //         setFormData({
    //             ...formData,
    //             User_Cell: formData.User_Cell.map((item, idx) => (idx === index ? value : item)),
    //         });
    //     } else if (name.includes('User_Email')) {
    //         const index = parseInt(name.match(/\[(\d+)\]/)[1]);
    //         setFormData({
    //             ...formData,
    //             User_Email: formData.User_Email.map((item, idx) => (idx === index ? value : item)),
    //         });
    //     } else {
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     }
    // };

    const handleSelectChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // For the date picker
    const handleDateChange = (selectedDates) => {
        const date = selectedDates[0];
        setFormData((prevData) => ({
            ...prevData,
            User_Account_Expiry: date.toISOString().split('T')[0],
        }));
    };

    useEffect(() => {
        async function fetchCsrfToken() {
            try {
                const response = await fetch('http://127.0.0.1:8000/csrf-token');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.csrf_token);
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        }

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/company-users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (response.ok) {
                setSuccessMessage('Badword added successfully');
                setUser_Name('');
                setUser_Description('');
                setUser_Pass('');
                setUser_Account_Expiry('');
                setSelectedMasking(null);
                setSuccessModalOpen(true);
                setErrorModalOpen(false);
            } else {
                setErrorMessage(data.message || 'Unknown error');
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting form:', error);
        }
    };
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Sent SMS" breadcrumbItem="Add Company" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <CardTitle className="h4">Add Company</CardTitle>
                                            <Col lg={8}>
                                                <p className="card-title-desc">
                                                    Add Api Key is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                </p>
                                            </Col>
                                            <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                <CardBody>
                                                    <div className="wizard clearfix">
                                                        <div className="steps clearfix">
                                                            <ul>
                                                                <NavItem
                                                                    className={classnames({ current: activeTab === 1 })}
                                                                >
                                                                    <NavLink
                                                                        className={classnames({ current: activeTab === 1 })}
                                                                        onClick={() => {
                                                                            setactiveTab(1)
                                                                        }}
                                                                        disabled={!(passedSteps || []).includes(1)}
                                                                    >
                                                                        <span className="number">1.</span> User Details
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem
                                                                    className={classnames({ current: activeTab === 2 })}
                                                                >
                                                                    <NavLink
                                                                        className={classnames({ active: activeTab === 2 })}
                                                                        onClick={() => {
                                                                            setactiveTab(2)
                                                                        }}
                                                                        disabled={!(passedSteps || []).includes(2)}
                                                                    >
                                                                        <span className="number">2.</span> User Contact
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem
                                                                    className={classnames({ current: activeTab === 3 })}
                                                                >
                                                                    <NavLink
                                                                        className={classnames({ active: activeTab === 3 })}
                                                                        onClick={() => {
                                                                            setactiveTab(3)
                                                                        }}
                                                                        disabled={!(passedSteps || []).includes(3)}
                                                                    >
                                                                        <span className="number">3.</span> Confirm Detail
                                                                    </NavLink>
                                                                </NavItem>
                                                            </ul>
                                                        </div>
                                                        <div className="content clearfix">
                                                            <TabContent activeTab={activeTab} className="body">
                                                                <TabPane tabId={1}>
                                                                    <Form>
                                                                        <Row>
                                                                            <Col lg="6">
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-firstname-input1">User name</Label>
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="basicpill-firstname-input1"
                                                                                        placeholder="Enter Your First Name"
                                                                                        name="User_Name"
                                                                                        value={formData.User_Name}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <div className="mb-3">
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <Label for="basicpill-lastname-input2">Password</Label>
                                                                                        <small className="text-danger">
                                                                                            Leave this blank if you don't want to change it
                                                                                        </small>
                                                                                    </div>
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="basicpill-lastname-input2"
                                                                                        placeholder="Enter your password "
                                                                                        name="User_Pass"
                                                                                        value={formData.User_Pass}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-phoneno-input3">Company</Label>
                                                                                    <select
                                                                                        name="Company_ID"
                                                                                        className="form-select"
                                                                                        value={formData.Company_ID}
                                                                                        onChange={handleSelectChange}
                                                                                    >
                                                                                        <option value="">Select a company</option>
                                                                                        {companies.map((company) => (
                                                                                            <option key={company.id} value={company.id}>
                                                                                                {company.name}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="6">
                                                                                <div className="docs-datepicker mb-3">
                                                                                    <Label>User Account Expiry</Label>
                                                                                    <InputGroup>
                                                                                        <Flatpickr
                                                                                            value={formData.User_Account_Expiry}
                                                                                            onChange={handleDateChange}
                                                                                            className="form-control d-block"
                                                                                            placeholder="Pick a date"
                                                                                            name="User_Account_Expiry"
                                                                                            options={{
                                                                                                altInput: true,
                                                                                                altFormat: "F j, Y",    
                                                                                                dateFormat: "Y-m-d", 
                                                                                            }}
                                                                                        />
                                                                                        <div className="input-group-append">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                disabled
                                                                                            >
                                                                                                <i className="fa fa-calendar" aria-hidden="true" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </InputGroup>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <Label for="basicpill-address-input1">User Description</Label>
                                                                                    <textarea
                                                                                        id="basicpill-address-input1"
                                                                                        className="form-control"
                                                                                        rows="2"
                                                                                        placeholder="Enter Your Address"
                                                                                        name="User_Description"
                                                                                        value={formData.User_Description}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>
                                                                </TabPane>
                                                                <TabPane tabId={2}>
                                                                    <div>
                                                                        <Form>
                                                                            <Row>
                                                                                {Array.from({ length: 4 }, (_, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        <Col lg="6">
                                                                                            <div className="mb-3">
                                                                                                <Label for={`basicpill-cell-input${index}`}>
                                                                                                    Authorized Cell Number ({index + 1})
                                                                                                </Label>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id={`basicpill-cell-input${index}`}
                                                                                                    placeholder="Enter Cell Number"
                                                                                                    name={`User_Cell[${index}]`}
                                                                                                    value={formData.User_Cell[index] || ''}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col lg="6">
                                                                                            <div className="mb-3">
                                                                                                <Label for={`basicpill-email-input${index}`}>
                                                                                                    Authorized Email Address ({index + 1})
                                                                                                </Label>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id={`basicpill-email-input${index}`}
                                                                                                    placeholder="Enter Email Address"
                                                                                                    name={`User_Email[${index}]`}
                                                                                                    value={formData.User_Email[index] || ''}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </Row>
                                                                        </Form>
                                                                    </div>
                                                                </TabPane>

                                                                <TabPane tabId={3}>
                                                                    <div className="row justify-content-center">
                                                                        <Col lg="6">
                                                                            <div className="text-center">
                                                                                <div className="mb-4">
                                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                                </div>
                                                                                <div>
                                                                                    <h5>Confirm Detail</h5>
                                                                                    <p className="text-muted">
                                                                                        If several languages coalesce, the grammar
                                                                                        of the resulting
                                                                                    </p>
                                                                                    <Link
                                                                                        className="btn btn-primary "
                                                                                        to="#"
                                                                                        onClick={handleSubmit}
                                                                                    >
                                                                                        Submit
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </TabPane>
                                                            </TabContent>
                                                        </div>
                                                        <div className="actions clearfix">
                                                            <ul>
                                                                <li
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <Link
                                                                        to="#"
                                                                        onClick={() => {
                                                                            toggleTab(activeTab - 1)
                                                                        }}
                                                                    >
                                                                        Previous
                                                                    </Link>
                                                                </li>
                                                                <li className={activeTab === 3 ? "next disabled" : null}>
                                                                    {activeTab !== 3 && (
                                                                        <Link to="#" onClick={() => {
                                                                            toggleTab(activeTab + 1)
                                                                        }}>
                                                                            Next
                                                                        </Link>
                                                                    )}
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                    <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                        Success
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-checkmark h3 text-success"></i>
                        <p className="mt-3">{successMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleSuccessModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                    <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                        Error
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-wrong h1 text-danger"></i>
                        <p className="mt-3">{errorMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleErrorModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
            </div >
        </React.Fragment >
    )
}

export default AddCompanyUsers;
