import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import TableContainer from "components/Common/TableContainer";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";

function ViewBadwordGroups() {
    document.title = "Manage Badwords Groups";

    const [modalData, setModalData] = useState({
        ID: '',
        group_name: '',
        group_desc: '',
        total: '',
        user_id: '',
        created_at: ''
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    useEffect(() => {
        // Fetch Badwords Groups when component mounts
        fetchBadwordsGroups();
    }, []);

    const token = localStorage.getItem('token')

    const fetchBadwordsGroups = async () => {
        try {
            const response = await fetch(`${BASE_URL}/badwordsgroups`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch Badwords Groups');
            }
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching Badwords Groups:', error);
        }
    };

    const handleEditClick = (rowData) => {
        setModalData(rowData.original);
        toggleEditModal();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSaveModal = async () => {
        try {
            const id = modalData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/badwordsgroups/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ group_name: modalData.group_name, }),
            });
            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message)
                setErrorMessage(data.errors)
                setSuccessModalOpen(true)
                setErrorModalOpen(false)
                setOrders(prevOrders => prevOrders.map(order =>
                    order.ID === modalData.ID ? { ...order, ...modalData } : order));
                toggleEditModal();

            } else {
                const errorData = await response.json();
                console.error(errorData.error);
                setErrorMessage('Error updating contact: ' + errorData.error);
                setSuccessMessage('');
                setErrorModalOpen(true);
            }
        } catch (error) {
            console.error('Error updating Badword group:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData.original && rowData.original.ID) {
            setEditingRowData(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };


    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.original.ID;
            console.log(id);

            const response = await fetch(`${BASE_URL}/badwordsgroups/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.original.ID }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.ID !== rowData.original.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
                setSuccessModalOpen(true);
                setSuccessMessage('Badword group deleted successfully');
                setErrorMessage('');

            } else {
                const data = await response.json();
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
                setErrorMessage(data.message);
                setSuccessMessage(data.errors);
                console.error('Failed to delete Badword group');
            }
        } catch (error) {
            console.error('Error deleting Badword group:', error);
        }
    };

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'ID', disableFilters: true, },
        { Header: 'Group Name', accessor: 'group_name', disableFilters: true, },
        { Header: 'Creation At', accessor: 'created_time', disableFilters: true, },
        { Header: 'Posted By', accessor: 'name', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Badwords" breadcrumbItem="Manage Badwords Groups" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Badwords Group</ModalHeader>
                        <ModalBody>
                            <Label>Group Name</Label>
                            <Input value={modalData.group_name} name="group_name" onChange={handleInputChange} />
                            <div className="modal-footer">
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    <SuccessModal
                        isOpen={successModalOpen}
                        toggle={toggleSuccessModal}
                        successMessage={successMessage}
                    />
                    <ErrorModal
                        isOpen={errorModalOpen}
                        toggle={toggleErrorModal}
                        errorMessage={errorMessage}
                    />
                    <DeleteModal
                        backdrop="static"
                        isOpen={deleteModal}
                        toggle={() => setDeleteModal(false)}
                        handleDeleteOrder={handleDeleteOrder}
                        rowData={editingRowData}
                        value={'SMS Template'}
                    />

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-badwords'}
                                        linkValue={'Add Badword Group'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewBadwordGroups