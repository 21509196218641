import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

function AddCompany() {
    document.title = "Add Company";

    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyNTN, setCompanyNTN] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');
    const [selectedFiles, setselectedFiles] = useState([]);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const token = localStorage.getItem('token');


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setCompanyName(value);
        } else if (name === 'email') {
            setCompanyEmail(value);
        } else if (name === 'phone_number') {
            setCompanyPhone(value);
        } else if (name === 'address') {
            setCompanyAddress(value);
        } else if (name === 'website') {
            setCompanyWebsite(value);
        } else if (name === 'NTN') {
            setCompanyNTN(value);
        } else if (name === 'logo') {
            setCompanyLogo(value);
        }
    };

    const handleGroupChange = (e) => {
        setBadwordGroupValue(e.target.value);
    }


    const handleReset = () => {
        setCompanyName('');
        setCompanyEmail('');
        setCompanyPhone('');
        setCompanyAddress('');
        setCompanyWebsite('');
        setCompanyNTN('');
        setCompanyLogo('');
        setBadwordGroupValue('');
        setSelectedMasking(null);
        setSuccessMessage('');
        setErrorMessage('');
    };

    useEffect(() => {
        async function fetchCsrfToken() {
            try {
                const response = await fetch('http://127.0.0.1:8000/csrf-token');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.csrf_token);
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        }

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/companies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: companyName,
                    email: companyEmail || '',
                    phone_number: companyPhone || '',
                    address: companyAddress || '',
                    website: companyWebsite || '',
                    NTN: companyNTN,
                    logo: companyLogo || '',
                })
            });
            const data = await response.json();
            if (response.ok) {
                setSuccessMessage('Badword added successfully');
                setCompanyName('');
                setCompanyEmail('');
                setCompanyPhone('');
                setCompanyAddress('');
                setCompanyWebsite('');
                setCompanyNTN('');
                setCompanyLogo('');
                setSelectedMasking(null);
                setSuccessModalOpen(true);
                setErrorModalOpen(false);
            } else {
                setErrorMessage(data.message || 'Unknown error');
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting form:', error);
        }
    };

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Sent SMS" breadcrumbItem="Add Company" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <CardTitle className="h4">Add Company</CardTitle>
                                            <Col lg={8}>
                                                <p className="card-title-desc">
                                                    Add Api Key is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                </p>
                                            </Col>
                                            <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div data-repeater-list="outer-group" className="outer">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Company Name : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="name"
                                                                            value={companyName}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Company Email : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="email"
                                                                            value={companyEmail}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Contact Number : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="phone_number"
                                                                            value={companyPhone}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Company Website : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="website"
                                                                            value={companyWebsite}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Company Address : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="address"
                                                                            value={companyAddress}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Company NTN : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter Key name"
                                                                            name="NTN"
                                                                            value={companyNTN}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Add Company Logo : </Label>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <Dropzone
                                                                                    onDrop={acceptedFiles => {
                                                                                        handleAcceptedFiles(acceptedFiles)
                                                                                    }}
                                                                                >
                                                                                    {({ getRootProps, getInputProps }) => (
                                                                                        <div className="dropzone">
                                                                                            <div
                                                                                                className="dz-message needsclick"
                                                                                                {...getRootProps()}
                                                                                            >
                                                                                                <input {...getInputProps()} name="logo" value={companyLogo} onChange={handleChange} />
                                                                                                <div className="mb-3">
                                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                                </div>
                                                                                                <h5>Drop logo here or click to upload.</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Dropzone>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="dropzone-previews" id="file-previews">
                                                                                    {selectedFiles.map((f, i) => {
                                                                                        return (
                                                                                            <Card
                                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                                key={i + "-file"}
                                                                                            >
                                                                                                <div className="p-2">
                                                                                                    <Row className="align-items-center">
                                                                                                        <Col className="col-auto" style={{ height: '150px' }}>
                                                                                                            <img
                                                                                                                data-dz-thumbnail=""
                                                                                                                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                                                                                className="rounded bg-light"
                                                                                                                alt={f.name}
                                                                                                                src={f.preview}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Link
                                                                                                                to="#"
                                                                                                                className="text-muted font-weight-bold"
                                                                                                            >
                                                                                                                {f.name}
                                                                                                            </Link>
                                                                                                            <p className="mb-0">
                                                                                                                <strong>{f.formattedSize}</strong>
                                                                                                            </p>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </Card>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="d-flex justify-content-end gap-2 mt-4">
                                                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                    <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                        Success
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-checkmark h3 text-success"></i>
                        <p className="mt-3">{successMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleSuccessModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                    <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                        Error
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-wrong h1 text-danger"></i>
                        <p className="mt-3">{errorMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleErrorModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
            </div >
        </React.Fragment >
    )
}

export default AddCompany;
