import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import BASE_URL from "Config/config";
import { Spinner } from "reactstrap";

const Authmiddleware = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setAuthenticated(false);
          return;
        }

        const response = await fetch(`${BASE_URL}/authenticated`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /><br />
        <span style={{ marginLeft: '10px' }}>Loading...</span>
      </div>
    );
  }

  return authenticated ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  );
};

export default Authmiddleware;
