import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";
import TableContainer from '../Common/TableContainer';
import BASE_URL from 'Config/config';

function ContactModalTables({ contactModal, handleContactModal }) {

    const [contacts, setContacts] = useState([]);
    const token = localStorage.getItem("token");
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = useMemo(() => [
        {
            Header: 'Select',
            id: 'selection',
            accessor: 'select',
            disableFilters: true, 
            Cell: ({ row }) => {
                return (
                    <input
                        type="checkbox"
                        checked={selectedRows.includes(row.index)}
                        onChange={() => toggleRowSelected(row.index)}
                    />
                );
            }
        },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Contact No.', accessor: 'contact' },
        { Header: 'Contact Email', accessor: 'email' },
        { Header: 'Group', accessor: 'group' },
    ], [selectedRows]);

    const toggleRowSelected = (index) => {
        const isIndexSelected = selectedRows.includes(index);
        if (isIndexSelected) {
            setSelectedRows(selectedRows.filter(i => i !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    useEffect(() => {
        async function fetchContactGroups() {
            try {
                const response = await fetch(`${BASE_URL}/contacts`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setContacts(data.map(contact => ({
                        name: contact.Name,
                        contact: contact.Cell_No,
                        email: contact.Contact_Email,
                        group: contact.GroupName,
                    })));
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error("Fetching error:", error);
            }
        }

        fetchContactGroups();
    }, [token]);

    const selectedContacts = contacts
        .filter((contact, index) => selectedRows.includes(index))
        .map(contact => contact.contact)
        .join(','); 

    const handleCopyContacts = () => {
        navigator.clipboard.writeText(selectedContacts)
            .then(() => {
                console.log('Contacts copied successfully');
                handleContactModal();
            })
            .catch(err => {
                console.error('Failed to copy contacts', err);
                handleContactModal();
            });
    };

    return (
        <Modal isOpen={contactModal} toggle={handleContactModal} size="xl">
            <ModalHeader>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Add Contact
                </h5>
            </ModalHeader>
            <ModalBody className="me-5 ms-5">
                <TableContainer
                    getToggleAllRowsSelectedProps= {false}
                    columns={columns}
                    data={contacts.map((data, index) => ({ ...data, key: index }))}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
                <h5>Selected Contacts</h5>
                <Input type="text" value={selectedContacts} readOnly />
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={handleContactModal} className="btn btn-secondary " data-dismiss="modal" >
                    Close
                </Button>
                <Button type="button" onClick={handleCopyContacts} className="btn btn-success ">
                    Copy Contacts
                </Button>
            </ModalFooter>
        </Modal>
    );
}

ContactModalTables.propTypes = {
    contactModal: PropTypes.bool.isRequired,
    handleContactModal: PropTypes.func.isRequired,
};

export default ContactModalTables;
