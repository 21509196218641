import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
    ModalFooter,
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import CustomStatusSelect from "components/MyComponents/CustomStatusSelect";
import Select from "react-select";
import TableContainer from "components/Common/TableContainer";
import ErrorModal from "components/MyComponents/ErrorModal";
import SuccessModal from "components/MyComponents/SuccessModal";

function ViewMaskingDirectory() {
    document.title = "View Masking Directory";

    const [modalData, setModalData] = useState({
        ID: '',
        Mask: '',
        Company_ID: '',
        created_it: '',
        status: '',
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedMasking, setSelectedMasking] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const token = localStorage.getItem('token')

    const handleEditClick = (rowData) => {
        setModalData(rowData.original);
        toggleEditModal();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        try {
            const response = await fetch(`${BASE_URL}/companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                const companyData = data.map((company) => ({
                    label: company.name,
                    value: company.id
                }));
                setCompanies(companyData);
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/masking`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data.map(maskingDirectory => ({
                    ...maskingDirectory,
                    company_name: maskingDirectory.company_name
                })));
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [token]);

    const getUpdatedData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/masking`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    const handleSaveModal = async () => {
        try {
            const id = modalData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/masking/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    Mask: modalData.Mask,
                    Company_ID: modalData.Company_ID
                }),
            });
            if (response.ok) {
                getUpdatedData();
                setSuccessMessage('Company data updated successfully!');
                setSuccessModalOpen(true);
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === modalData.ID ? { ...order, ...modalData } : order));
                toggleEditModal();
                console.log(modalData);
            }
            else {
                console.error('Failed to update Company data');
                setErrorMessage('Error updating Company data: ' + response.statusText);
                setErrorModalOpen(true);
            }
        } catch (error) {
            console.error('Error updating Company data:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData.original && rowData.original.ID) {
            setEditingRowData(rowData);
            console.log(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };


    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.original.ID;
            console.log(id);

            const response = await fetch(`${BASE_URL}/masking/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.original.ID }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.ID !== rowData.original.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
                console.log(rowData.original);
            } else {
                console.error('Failed to delete Company data');
            }
        } catch (error) {
            console.error('Error deleting Company data:', error);
        }
    };

    const handleSelectMasking = (selectedOption) => {
        setSelectedMasking(selectedOption);
        setModalData(prevData => ({
            ...prevData,
            Company_ID: selectedOption.value
        }));
    };

    const options = [
        { value: '1', label: 'Active', icon: <i className="bx bx-check-circle text-success"></i> },
        { value: '2', label: 'Inactive', icon: <i className="bx bx-x-circle text-danger"></i> }
    ];

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'ID', disableFilters: true, },
        { Header: 'Masking Name', accessor: 'Mask', disableFilters: true, },
        { Header: 'Company Name', accessor: 'name', disableFilters: true, },
        { Header: 'Creation Date', accessor: 'created_it', disableFilters: true, },
        { Header: 'Masking Status', accessor: 'status', disableFilters: true, },
    ], []);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Masking" breadcrumbItem="Manage Masking Directory" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Company </ModalHeader>
                        <ModalBody>
                            <Label>Category Name</Label>
                            <Input value={modalData.Mask} name="Mask" className="mb-3" onChange={handleInputChange} />
                            <Label>Company</Label>
                            <Select
                                options={companies}
                                value={companies.find(company => company.value === modalData.Company_ID)}
                                name="Company_ID"
                                onChange={handleSelectMasking}
                            />
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>
                    <SuccessModal
                        isOpen={successModalOpen}
                        toggle={toggleSuccessModal}
                        successMessage={successMessage}
                    />
                    <ErrorModal
                        isOpen={errorModalOpen}
                        toggle={toggleErrorModal}
                        errorMessage={errorMessage}
                    />
                    <DeleteModal
                        backdrop="static"
                        isOpen={deleteModal}
                        toggle={() => setDeleteModal(false)}
                        handleDeleteOrder={handleDeleteOrder}
                        rowData={editingRowData}
                        value={'Masking Data'}
                    />

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-masking'}
                                        linkValue={'Add Masking'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewMaskingDirectory 