// const BASE_URL = 'http://localhost/api'; 

// export default BASE_URL;

// config.js

let BASE_URL;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://127.0.0.1:8000/api';
} else {
  BASE_URL = 'https://test.branded-sms.pk/api'; 
}

export default BASE_URL;
