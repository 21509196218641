import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";

function AddBadwords() {
    document.title = "Add Badwords";

    const [messageValue, setMessageValue] = useState('');
    const [badwordGroupValue, setBadwordGroupValue] = useState('');
    const [selectedMasking, setSelectedMasking] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const [optionMasking, setOptionMasking] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');
  
    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);  

    useEffect(() => {
        // Fetch data for Select options
        fetchBadwordGroups();
    }, []);

    const token = localStorage.getItem('token');

    const fetchBadwordGroups = async () => {
        try {
            const response = await fetch(`${BASE_URL}/badwordsgroups`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            // Format data for Select options
            const options = data.map(group => ({ value: group.group_name, label: group.group_name }));
            setOptionMasking(options);
        } catch (error) {
            console.error('Error fetching badword groups:', error);
        }
    };

    const handleSelectMasking = (selectedOption) => {
        setSelectedMasking(selectedOption);
    };

    const handleKeywordChange = (e) => {
        setMessageValue(e.target.value);
    }

    const handleGroupChange = (e) => {
        setBadwordGroupValue(e.target.value);
    }


    const handleReset = () => {
        setMessageValue('');
        setBadwordGroupValue('');
        setSelectedMasking(null);
        setSuccessMessage('');
        setErrorMessage('');
    };

    useEffect(() => {
        async function fetchCsrfToken() {
            try {
                const response = await fetch('http://127.0.0.1:8000/csrf-token');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.csrf_token);
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        }

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/create-badwords`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    keyword: messageValue,
                    group_name: selectedMasking ? selectedMasking.value : '' // Ensure this matches the group name
                })
            });
            const data = await response.json(); // Use json() instead of text() if expecting JSON response
            if (response.ok) {
                setSuccessMessage('Badword added successfully');
                setMessageValue('');
                setSelectedMasking(null);
                setSuccessModalOpen(true);
                setErrorModalOpen(false);
            } else {
                setErrorMessage(data.message || 'Unknown error');
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting form:', error);
        }
    };    

    const handleBadwordGroupSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/badwordsgroups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    group_name: badwordGroupValue
                })
            });
            const data = await response.json();
            setSuccessMessage('Badword group added successfully');
            console.log(data);
            setBadwordGroupValue('');
        } catch (error) {
            setErrorMessage('Error adding badword group');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Sent SMS" breadcrumbItem="Add Api Key" />
                    <Row>
                        <Col xl={7}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => setActiveTab("1")}
                                    >
                                        Create New Badword
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={activeTab === "2" ? "active" : ""}
                                        onClick={() => setActiveTab("2")}
                                    >
                                        Create New Group
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId='1'>
                                                    <CardTitle className="h4">Add Badwords Key</CardTitle>
                                                    <Col lg={8}>
                                                        <p className="card-title-desc">
                                                            Add Api Key is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                        </p>
                                                    </Col>
                                                    <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div data-repeater-list="outer-group" className="outer">
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Bad Keyword : </Label>
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Please enter Key name"
                                                                                    name="keyword"
                                                                                    value={messageValue}
                                                                                    onChange={handleKeywordChange}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Add Group : </Label>
                                                                                <Select
                                                                                    options={optionMasking}
                                                                                    value={selectedMasking}
                                                                                    name="group_name"
                                                                                    onChange={handleSelectMasking}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex justify-content-end gap-2 mt-4">
                                                                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                        <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId='2'>
                                                    <CardTitle className="h4">Add Group Name </CardTitle>
                                                    <Col lg={8}>
                                                        <p className="card-title-desc">
                                                            Add Group Name is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                        </p>
                                                    </Col>
                                                    <Form className="outer-repeater" onSubmit={handleBadwordGroupSubmit}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div data-repeater-list="outer-group" className="outer">
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Group Name : </Label>
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Please enter Key name"
                                                                                    value={badwordGroupValue}
                                                                                    onChange={handleGroupChange}
                                                                                    name="group"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex justify-content-end gap-2 mt-4">
                                                                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                        <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
          <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
            Success
          </ModalHeader>
          <ModalBody className="text-center">
            <i className="dripicons-checkmark h3 text-success"></i>
            <p className="mt-3">Your template has been successfully created!</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={toggleSuccessModal}
              className="btn btn-light w-100"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
          <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
            Error
          </ModalHeader>
          <ModalBody className="text-center">
            <i className="dripicons-wrong h1 text-danger"></i>
            <p className="mt-3">{errorMessage}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={toggleErrorModal}
              className="btn btn-light w-100"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddBadwords;
