import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Form,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import './MobileStyling/mobileStyling.css'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BASE_URL from "Config/config";


function containsBadWord(text, badWords) {
  if (!badWords || badWords.length === 0 || !text) {
    return false;
  }

  const lowerCaseText = text.toLowerCase();

  return badWords.some(word => {
    const wordString = word.keyword.toString().toLowerCase();
    return lowerCaseText.includes(wordString);
  });
}

function AddBuiltinTemplate() {
  const [messageValue, setMessageValue] = useState('create your template ');
  const [activeTab, setActiveTab] = useState("1");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [smsCounter, setSmsCounter] = useState({
    encoding: "",
    length: 0,
    per_message: 0,
    remaining: 0,
    messages: 0
  });
  const createMessageRef = useRef(null);
  const editMessageRef = useRef(null);
  const [templateNameInput, setTemplateNameInput] = useState("");
  const [errors, setErrors] = useState({});
  const [badWords, setBadWords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [visible, setVisible] = useState(true);
  const [csrfToken, setCsrfToken] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchBadword = async () => {
      try {
        const response = await fetch(`${BASE_URL}/view-badwords`, {
          method: "GET",
          headers: {
            "Content-Type": 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else {
          const data = await response.json();
          setBadWords(data);
        }
      }
      catch (error) {
        console.error('Error fetching badwords:', error);
      }
    };

    fetchBadword();
  }, []);

  useEffect(() => {
    function countSms(text) {
      const SmsCounter = {
        GSM_7BIT: 'GSM_7BIT',
        GSM_7BIT_EX: 'GSM_7BIT_EX',
        UTF16: 'UTF16',
        messageLength: {
          GSM_7BIT: 160,
          GSM_7BIT_EX: 160,
          UTF16: 70
        },
        multiMessageLength: {
          GSM_7BIT: 153,
          GSM_7BIT_EX: 153,
          UTF16: 67
        },
        count: function (text) {
          let encoding = this.detectEncoding(text);
          let length = text.length;
          let per_message = this.messageLength[encoding];
          if (length > per_message) {
            per_message = this.multiMessageLength[encoding];
          }
          let messages = Math.ceil(length / per_message);
          let remaining = (per_message * messages) - length;
          return {
            encoding: encoding,
            length: length,
            per_message: per_message,
            remaining: remaining,
            messages: messages
          };
        },
        detectEncoding: function (text) {

          if (text.trim() === '') {
            return ' ';
          }
          // Use try-catch to handle exceptions
          try {
            if (text.match(RegExp("^[" + this.gsm7bitChars + "]*$", "gi"))) {
              return this.GSM_7BIT;
            } else if (text.match(RegExp("^[" + this.gsm7bitChars + this.gsm7bitExChar + "]*$", "gi"))) {
              return this.GSM_7BIT_EX;
            } else {
              return this.UTF16;
            }
          } catch (error) {
            console.error("Error detecting encoding:", error);
            return this.GSM_7BIT;
          }
        },
        gsm7bitChars: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
        gsm7bitExChar: "\\^{}\\\\\\[~\\]|€"
      };

      const count = SmsCounter.count(text);
      return count;
    }

    if (activeTab === "1") {
      setSmsCounter(countSms(messageValue));
    } else if (activeTab === "2") {
      setSmsCounter(countSms(messageContent));
    }
  }, [messageValue, messageContent, activeTab]);

  useEffect(() => {
    const updateMaxLength = () => {
      const textarea = activeTab === "1" ? createMessageRef.current : editMessageRef.current;
      if (!textarea) return;

      switch (smsCounter.encoding) {
        case "GSM_7BIT":
          textarea.setAttribute("maxlength", "765");
          break;
        case "GSM_7BIT_EX":
          textarea.setAttribute("maxlength", "763");
          break;
        case "UTF16":
          textarea.setAttribute("maxlength", "335");
          break;
        default:
          textarea.setAttribute("maxlength", "765");
          break;
      }
    };

    updateMaxLength();
  }, [smsCounter.encoding, activeTab]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = currentTime.getHours();
  const isPM = hours >= 12;
  const displayHours = isPM ? hours % 12 || 12 : hours;
  const minutes = String(currentTime.getMinutes()).padStart(2, '0');
  const timeString = `${displayHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;


  const [formData, setFormData] = useState({
    templateName: '',
    smsTemplate: '',
    encoding: '',
    message_length: 0,
    message_number: 0
  });

  const onDismiss = () => setVisible(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "templateName") {
      setTemplateNameInput(value);
    }
    // setMessageValue(value);
    setFormData({ ...formData, [name]: value });
  };

  const handleTextChange = (e) => {
    setMessageValue(e.target.value)
  }

  const handleTemplateSelection = (template) => {
    setSelectedTemplate(template);
    setMessageValue(template.content);
  };

  useEffect(() => {
    async function fetchCsrfToken() {
      try {
        const response = await fetch('http://127.0.0.1:8000/csrf-token'); // This should be a web route
        if (response.ok) {
          const data = await response.json();
          setCsrfToken(data.csrf_token);
        } else {
          console.error('Failed to fetch CSRF token');
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    }

    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});
    setSuccessMessage('');
    setErrorMessage('');

    const nameErrors = {};
    if (templateNameInput.length > 50) {
      nameErrors.templateName = "Template name cannot exceed 50 characters";
    } else if (templateNameInput.length < 3) {
      nameErrors.templateName = "Template name must be at least 3 characters";
    } else if (templateNameInput.trim() === '') {
      nameErrors.templateName = "Template name is required";
    } else if (templateNameInput.match(/[^a-zA-Z0-9 ]/)) {
      nameErrors.templateName = "Template name cannot contain special characters";
    }
    setErrors(nameErrors);

    if (containsBadWord(templateNameInput, badWords)) {
      nameErrors.templateName = "Template name contains inappropriate words";
      setErrors(nameErrors);
      setErrorModalOpen(true);
      setSuccessModalOpen(false);
      return;
    } else {
      setErrorModalOpen(false);
      setSuccessModalOpen(true);
    }

    const smsErrors = {};
    const smsTemplate = activeTab === "1" ? messageValue : messageContent;
    if (smsTemplate.length > 765) {
      smsErrors.smsTemplate = "SMS template cannot exceed 765 characters";
    } else if (smsTemplate.length < 3) {
      smsErrors.smsTemplate = "SMS template must be at least 3 characters";
    } else if (smsTemplate.trim() === '') {
      smsErrors.smsTemplate = "SMS template is required";
    }
    setErrors(smsErrors);

    if (containsBadWord(smsTemplate, badWords)) {
      smsErrors.smsTemplate = "SMS template contains inappropriate words";
      setErrors(smsErrors);
      setErrorModalOpen(true);
      setSuccessModalOpen(false);
      return;
    } else {
      setErrorModalOpen(false);
      setSuccessModalOpen(true);
    }

    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const messageLength = activeTab === "1" ? messageValue.length : messageContent.length;
      const perMessage = smsCounter.encoding === "GSM_7BIT" ? 160 : 70;
      const messageNumber = Math.ceil(messageLength / perMessage);

      const formData = {
        templateName: templateNameInput,
        smsTemplate: activeTab === "1" ? messageValue : messageContent,
        encoding: smsCounter.encoding,
        message_length: messageLength,
        message_number: messageNumber
      };

      try {
        const response = await fetch(`${BASE_URL}/create-builtin-templates`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          setSuccessMessage('Template added successfully.');
          setErrorMessage('');
        } else {
          setErrorMessage('Error adding template.');
          setSuccessMessage('');
        }
      } catch (error) {
        console.error('Submission error:', error);
        setErrorMessage('Error adding template.');
        setSuccessMessage('');
      }
      setMessageValue('');
      setSelectedTemplate('');
      setMessageContent('');
      setSmsCounter({
        encoding: "",
        length: 0,
        per_message: 0,
        remaining: 0,
        messages: 0
      });
    }
  };

  const handleReset = () => {
    setTemplateNameInput('');
    setMessageValue('');
    setSmsCounter({
      encoding: 'GSM_7BIT',
      length: 0,
      messages: 0
    });
  };

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  document.title = "Add SMS Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="SMS Templates" breadcrumbItem="Add Builtin Template For User" />
          <Card>
            <CardBody>
              <Row>
                <Col xl={8}>
                  {successMessage && <Alert color="success" isOpen={visible} toggle={onDismiss}>{successMessage}</Alert>}
                  {errorMessage && <Alert color="danger" isOpen={visible} toggle={onDismiss}>{errorMessage}</Alert>}
                  <CardTitle className="h4">Add New SMS Templates</CardTitle>
                  <p className="card-title-desc">
                    Streamline your messaging experience. Easily customize and deploy SMS templates for powerful & quicker communication.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <Col lg={12} className="mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <label htmlFor="name">Template Name</label>
                        <small className="btn btn-sm btn-soft-info">
                          Max. 50 Chars
                        </small>
                      </div>
                      <Input
                        type="text"
                        id="name"
                        name="templateName"
                        className={`form-control ${errors.templateName ? 'is-invalid' : ''}`}
                        placeholder="Enter Your Template Name"
                        value={templateNameInput}
                        onChange={handleChange}
                        maxLength={50}

                      />
                      {errors.templateName && <div className="invalid-feedback">{errors.templateName}</div>}
                    </Col>
                    <Col lg={12} className="mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">

                        <label htmlFor="message">Create SMS Template</label>
                        <small className={`btn btn-sm btn-soft-${smsCounter.encoding === 'GSM_7BIT' ? 'info' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'primary' : 'danger'}`}>
                          {smsCounter.encoding === 'GSM_7BIT' ? 'MAX: 765' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'MAX: 755' : 'MAX: 355'} Characters ({smsCounter.encoding})
                        </small>
                      </div>
                      <Input
                        type="textarea"
                        rows={10}
                        id="create-message"
                        name='smsTemplate'
                        className={`form-control ${errors.smsTemplate ? 'is-invalid' : ''}`}
                        placeholder="Enter Your Message"
                        value={messageValue}
                        onChange={handleTextChange}
                        maxLength={smsCounter.encoding === "GSM_7BIT" ? 765 : smsCounter.encoding === "GSM_7BIT_EX" ? 763 : 335}

                      />
                      {errors.smsTemplate && <div className="invalid-feedback">{errors.smsTemplate}</div>}
                    </Col>
                    <ul className=" btn btn-soft-danger waves-effect waves-light btn btn-secondary list-unstyled d-flex justify-content-between rounded">
                      <li>Message length: {smsCounter.length}</li>
                      <li>No. of Messages: {smsCounter.messages}</li>
                    </ul>
                    <div className="d-flex justify-content-end gap-2">
                      <button type="button" className="btn btn-primary w-md" onClick={handleSubmit}>Submit</button>
                      <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                    </div>
                  </Form>
                </Col>
                <Col lg={4}>
                  <div className="phone-graphic">
                    <div className="phone-case">
                      <div className="phone-container-outer">
                        <div className="phone-container-inner">
                          <div className="phone-header">
                            <p className="phone-header-time">{timeString}</p>
                            <p className="phone-header-icons"><i className="mdi mdi-signal"></i> <i className="mdi mdi-wifi"></i> <i className="mdi mdi-battery"></i></p>
                            <div className="contact-image"> <i className="fas fa-user-circle" style={{ fontSize: '35px' }}></i> </div>
                            <p className="contact-phone">Connect Pulse</p>
                          </div>
                          <div className="phone-messages" style={{ height: '70%', overflowY: 'auto' }}>
                            {activeTab === "1" && (
                              <>
                                <div className="message message-you">
                                  <p> Create New Template </p>
                                </div>
                                <div className="message message-contact">
                                  <p> {messageValue} </p>
                                </div>
                              </>
                            )}
                            {activeTab === "2" && (
                              <>
                                <div className="message message-you">
                                  <p> Use Existing Templates </p>
                                </div>
                                <div className="message message-contact" dangerouslySetInnerHTML={{ __html: messageContent.replace(/\n/g, "<br>") }}></div>
                              </>
                            )}
                          </div>
                          <div className="phone-footer">
                            <div className="phone-footer-icons"><i className="mdi mdi-camera" style={{ fontSize: '23px' }}></i></div>
                            <div className="phone-footer-input">
                              <p>Text Message</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
          <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
            Success
          </ModalHeader>
          <ModalBody className="text-center">
            <i className="dripicons-checkmark h1 text-success"></i>
            <p className="mt-3">Your template has been successfully created!</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={toggleSuccessModal}
              className="btn btn-light w-100"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
          <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
            Error
          </ModalHeader>
          <ModalBody className="text-center">
            <i className="dripicons-wrong h1 text-danger"></i>
            <p className="mt-3">Template name or your sms messsage contain inapporpirate words</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={toggleErrorModal}
              className="btn btn-light w-100"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

      </div>
    </React.Fragment >
  );
}

export default AddBuiltinTemplate;
