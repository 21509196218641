import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table,
    CardTitle
} from "reactstrap";
import BASE_URL from "Config/config";

function KeyApiDocs() {
    document.title = "Activity Logs Reports";

    const [userData, setUserData ] = useState([]);

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetch(`${BASE_URL}/user-data`,{
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => setUserData(data));
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Activity Logs" />
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <CardTitle className="h5"> SMS API (UserName & Password)</CardTitle>
                                    <hr style={{ border: '1px solid silver' }} />
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>WEB SERVER DATA</th>
                                                    <th>Provided to you by Reseller</th>
                                                </tr>
                                                <tr>
                                                    <td><b>SMSC DOMAIN/IP</b></td>
                                                    <td colSpan="2"><a href="https://connectpulse.net/API/SMS/User" style={{ display: 'inline-block', width: '280px;' }}>https://connectpulse.net/API/SMS/User/</a></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Username</b></td>
                                                    <td>{userData.data.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Password</b></td>
                                                    <td>*******</td>
                                                </tr>
                                                <tr>
                                                    <td><b>receiver</b></td>
                                                    <td>923022211112</td>
                                                </tr>
                                                <tr>
                                                    <td><b>sender</b></td>
                                                    <td>IISOL-Demo</td>
                                                </tr>
                                                <tr>
                                                    <td><b>msgdata</b></td>
                                                    <td>SMS text for Testing</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>TRANSACTIONAL EXAMPLE- HTTP OUTGOING TEST SMS API</th>
                                                </tr>
                                                <tr>
                                                    <td>https://connectpulse.net/API/SMS/User?username=&password=&receiver=&sender=&msgdata=</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <CardTitle className="h5"> SMS API (Key) </CardTitle>
                                    <hr style={{ border: '1px solid silver' }} />
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>WEB SERVER DATA</th>
                                                    <th>Provided to you by Reseller</th>
                                                </tr>
                                                <tr>
                                                    <td><b>SMSC DOMAIN/IP</b></td>
                                                    <td><a href="https://connectpulse.net/API/SMS/Key" style={{ display: 'inline-block', width: '280px;' }}>https://connectpulse.net/API/SMS/Key/</a></td>
                                                </tr>
                                                <tr>
                                                    <td><b>key</b></td>
                                                    <td>Ft6Xmdz0k3pDGQxNYj7eKifEIRCy4naO</td>
                                                </tr>
                                                <tr>
                                                    <td><b>receiver</b></td>
                                                    <td>923022211112</td>
                                                </tr>
                                                <tr>
                                                    <td><b>sender</b></td>
                                                    <td>IISOL-Demo</td>
                                                </tr>
                                                <tr>
                                                    <td><b>msgdata</b></td>
                                                    <td>SMS text for Testing</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>TRANSACTIONAL EXAMPLE- HTTP OUTGOING TEST SMS API</th>
                                                </tr>
                                                <tr>
                                                    <td>https://connectpulse.net/API/SMS/Key?key=&receiver=&sender=&msgdata=</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default KeyApiDocs