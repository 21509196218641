import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Container, Form, Input, Label, Spinner } from "reactstrap";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import profile from "assets/images/profile-img.png";
import logo from "assets/images/favicon-logo.png";
import { facebook, google } from "../../config";
import BASE_URL from 'Config/config';
import axios from 'axios';

function Login() {
  const googleResponse = response => {
    signIn(response, "google");
  };

  const facebookResponse = response => {
    signIn(response, "facebook");
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/csrf-token');
      const data = await response.json();
      setCsrfToken(data.csrf_token);
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
            withCredentials: true
          }
        }
      );
      // console.log(withCredentials);

      const data = response.data;
      if (response.status === 200 && data.status) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        navigate('/dashboard');
        console.log('not navigated')
        console.log("Login successful.");
      } else {
        toastr.error(
          data.message.invalid ||
          ((!email || !password) ?
            (email ? 'Password cannot be empty' : 'Email cannot be empty') :
            'Email or password cannot be empty'
          )
        );
        if (data.message.invalid) {
          setError(data.message.invalid);
        } else if (data.errors) {
          setError(data.errors);
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const data = error.response.data;
        toastr.error(
          data.message.invalid ||
          ((!email || !password) ?
            (email ? 'Password cannot be empty' : 'Email cannot be empty') :
            'Email or password cannot be empty'
          )
        );
        if (data.message.invalid) {
          setError(data.message.invalid);
        } else if (data.errors) {
          setError(data.errors);
        }
      } else {
        toastr.error('An unexpected error occurred.');
        console.error('An unexpected error occurred:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    toastr.options = {
      "closeButton": true,
      "progressBar": true,
      "positionClass": "toast-top-right",
    }
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          <span style={{ marginLeft: '10px' }}>Loading...</span>
        </div>
      ) : (
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Skote.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element d-block">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {error.email && (
                            <p className="text-danger mt-2">{error.email[0]}*</p>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auht-pass-inputgroup">
                            <Input
                              name="password"
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                              className="btn btn-light"
                              type="button"
                              onClick={togglePasswordVisibility}
                            >
                              <i className={`mdi ${showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'}`}></i>
                            </button>
                          </div>
                          {error.password && (
                            <p className="text-danger mt-2">{error.password[0]}*</p>
                          )}
                          {error && !error.email && !error.password && (
                            <p className="text-danger mt-3">
                              {error}*
                            </p>
                          )}
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                          <Link to="/forgot-password" className="text-muted float-end">
                            Forgot your password?
                          </Link>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={facebookResponse}
                                render={renderProps => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-primary text-white border-primary"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-facebook" />
                                  </Link>
                                )}
                              />
                            </li>
                            <li className="list-inline-item">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => { }}
                              />
                            </li>
                          </ul>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Don't have an account?{' '}
                    <Link to="/register" className="fw-medium text-primary">
                      Signup now
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} Skote. Crafted with{' '}
                    <i className="mdi mdi-heart text-danger" /> by Themesbrand
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}

export default Login;
