import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import BASE_URL from "Config/config";
import Select from "react-select";


function AddMasking() {
    document.title = "Add Masking";

    const [masking, setMasking] = useState('');
    const [companies, setCompanies] = useState([]);
    const [selectedMasking, setSelectedMasking] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    useEffect(() => {
        fetchCompany();
    }, []);

    const token = localStorage.getItem('token');

    const fetchCompany = async () => {
        try {
            const response = await fetch(`${BASE_URL}/companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                const companyData = data.map((company) => ({
                    label: company.name,
                    value: company.id
                }));
                setCompanies(companyData);
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    const handleKeywordChange = (e) => {
        setMasking(e.target.value);
    }

    const handleSelectMasking = (selectedOption) => {
        setSelectedMasking(selectedOption);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/masking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    Mask: masking,
                    Company_ID: selectedMasking ? selectedMasking.value : ''
                })
            });
            const data = await response.json();
            if (response.ok) {
                setSuccessMessage('Masking added successfully');
                setMasking('');
                setSelectedMasking(null);
                setSuccessModalOpen(true);
                setErrorModalOpen(false);
            } else {
                setErrorMessage(data.message || 'Unknown error');
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting form:', error);
        }
    };

    const handleReset = () => {
        setMasking('');
        setSelectedMasking(null);
        setSuccessMessage('');
        setErrorMessage('');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Masking" breadcrumbItem="Add Masking" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <CardTitle className="h4">Add Masking</CardTitle>
                                            <Col lg={12}>
                                                <p className="card-title-desc">
                                                    Masking is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                </p>
                                            </Col>
                                            <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div data-repeater-list="outer-group" className="outer">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Masking : </Label>
                                                                        <Input
                                                                            className="form-control"
                                                                            placeholder="Please enter masking name"
                                                                            name="Mask"
                                                                            value={masking}
                                                                            onChange={handleKeywordChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Add Company : </Label>
                                                                        <Select
                                                                            options={companies}
                                                                            value={selectedMasking}
                                                                            name="Company_ID"
                                                                            onChange={handleSelectMasking}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="d-flex justify-content-end gap-2 mt-4">
                                                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                    <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                        Success
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-checkmark h3 text-success"></i>
                        <p className="mt-3">{successMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleSuccessModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                    <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                        Error
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-wrong h1 text-danger"></i>
                        <p className="mt-3">{errorMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleErrorModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddMasking;
