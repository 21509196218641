import React from 'react'
import NoData from '../../assets/images/no-data-found.png'

function NoTableDataFound() {
  return (
    <div>
        <img src={NoData} alt="no-data-found" />
    </div>
  )
}

export default NoTableDataFound