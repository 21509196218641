import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
} from "reactstrap";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import CustomStatusSelect from "components/MyComponents/CustomStatusSelect";
import { options } from "toastr";

function ViewCompanyDetails() {
    document.title = "Manage Company Details";

    const [modalData, setModalData] = useState({
        id: '',
        name: '',
        phone_number: '',
        email: '',
        website: '',
        NTN: '',
        address: '',
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);

    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const token = localStorage.getItem('token')

    const handleEditClick = (rowData) => {
        setModalData(rowData);
        toggleEditModal();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/companies`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [token]);

    const handleSaveModal = async () => {
        try {
            const id = modalData.id;
            console.log(id);
            const response = await fetch(`${BASE_URL}/companies/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(modalData),
            });
            if (response.ok) {
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === modalData.id ? { ...order, ...modalData } : order));
                toggleEditModal();

            } else {
                console.error('Failed to update Company data');
            }
        } catch (error) {
            console.error('Error updating Company data:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData && rowData.id) {
            setEditingRowData(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };


    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.id;
            console.log(id);

            const response = await fetch(`${BASE_URL}/companies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.id }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.id !== rowData.id);
                setOrders(updatedOrders);
                setDeleteModal(false);
            } else {
                console.error('Failed to delete Company data');
            }
        } catch (error) {
            console.error('Error deleting Company data:', error);
        }
    };

    const options = [
        { value: '1', label: 'Active', icon: <i className="bx bx-check-circle text-success"></i> },
        { value: '2', label: 'Inactive', icon: <i className="bx bx-x-circle text-danger"></i> }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Company" breadcrumbItem="Manage Company" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Company </ModalHeader>
                        <ModalBody>
                            
                            <Label>Company Name</Label>
                            <Input value={modalData.name} name="name" className="mb-3" onChange={handleInputChange} />
                            
                            <Label>Company Phone</Label>
                            <Input value={modalData.phone_number} name="phone_number" className="mb-3" onChange={handleInputChange} />
                            
                            <Label>Company Email</Label>
                            <Input value={modalData.email} name="email" className="mb-3" onChange={handleInputChange} />
                            
                            <Label>Company Website</Label>
                            <Input value={modalData.website} name="website" className="mb-3" onChange={handleInputChange} />
                            
                            <Label>NTN</Label>
                            <Input value={modalData.NTN} name="NTN" className="mb-3" onChange={handleInputChange} />
                            
                            <Label>Company Logo</Label>
                            <Input value={modalData.logo} name="logo" className="mb-3" onChange={handleInputChange} />
                            
                            <div className="modal-footer">
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2 row">
                                        <div className="col-sm-2">
                                            <select className="form-select pageSize mb-2">
                                                <option value="10">Show 10</option>
                                                <option value="20">Show 20</option>
                                                <option value="30">Show 30</option>
                                                <option value="40">Show 40</option>
                                                <option value="50">Show 50</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <input className="form-control search-box me-2 mb-2 d-inline-block" placeholder="26 records..." />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-sm-end">

                                                <Link
                                                    to={'/add-company'}
                                                    type="button"
                                                    className="btn btn-success btn-rounded waves-effect waves-light addOrder-modal btn btn-secondary"
                                                >
                                                    <i className="mdi mdi-plus me-1"></i> Add New Company
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">
                                                            <div className="font-size-16 form-check">
                                                                <label className="form-check-label">
                                                                    <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">ID</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Company Name</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Contact No.</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">NTN</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Email</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Created Time</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Status</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Action</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Table rows */}
                                                {orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="cursor-pointer select-none">
                                                                <div className="font-size-16 form-check">
                                                                    <label className="form-check-label">
                                                                        <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{order.id}</td>
                                                        <td>{order.name}</td>
                                                        <td>{order.phone_number}</td>
                                                        <td>{order.NTN}</td>
                                                        <td>{order.email}</td>
                                                        <td>{order.created_it}</td>
                                                        <td className="ps-0 pe-5">
                                                            <CustomStatusSelect options={options} />
                                                        </td>
                                                        <td className="gap-2 d-flex">
                                                            <Link
                                                                className="btn btn-soft-info"
                                                                onClick={() => {
                                                                    handleEditClick(order);
                                                                    toggleEditModal();
                                                                }}
                                                            >
                                                                <i className="mdi mdi-pencil-outline font-size-18" id="edittooltip" />
                                                                <UncontrolledTooltip placement="top" target="edittooltip">
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                            <Link
                                                                className="btn btn-soft-danger"
                                                                onClick={() => handleDeleteClick(order)}
                                                            >
                                                                <i className="mdi mdi-delete-outline font-size-18" id="deletetooltip" />
                                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                    Delete
                                                                </UncontrolledTooltip>
                                                            </Link>

                                                            <DeleteModal
                                                                isOpen={deleteModal}
                                                                toggle={() => setDeleteModal(false)}
                                                                handleDeleteOrder={handleDeleteOrder}
                                                                rowData={editingRowData}
                                                                value={"Badword Group"}
                                                            />

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info">Showing 10 of 26 Results</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled">
                                                        <a className="page-link" href="/#">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active"><a className="page-link" href="/#">1</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/#">2</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/#">3</a></li>
                                                    <li className="paginate_button page-item next "><a className="page-link" href="/#"><i className="mdi mdi-chevron-right"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewCompanyDetails