import React, { useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import Breadcrumbs from './../../../components/Common/Breadcrumb';
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table
} from "reactstrap";


function AllNetwork() {
    document.title = "Manage Network Units Reports";

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        name: '',
        networks: '',
        debitsUnits: '',
        creditsUnits: '',
        postedBy: '',
        createdAt: '',
        cost: ''
    });
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([
        {
            id: 1,
            networks: 'All Network',
            debitsUnits: '0',
            creditsUnits: '0',
            afterUnits: '0.00',
            status: 'Minus',
            reason: 'Opening Balance',
            user: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm',
        },
        {
            id: 2,
            networks: 'All Networks',
            debitsUnits: '0',
            creditsUnits: `0`,
            afterUnits: '0.00',
            status: 'Plus',
            reason: 'Opening Balance',
            user: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm',
        }
    ]);


    const [editModal, setEditModal] = useState(false); 

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };


    const handleEditClick = (rowData) => {
        setEditingRowData(rowData);
        setModalData(rowData); // Pre-fill modalData with the selected contact creditsUnits data
        toggleEditModal(); // Open edit modal
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Manage Network Units " />
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2 row">
                                        <div className="col-sm-2">
                                            <select className="form-select pageSize mb-2">
                                                <option value="10">Show 10</option>
                                                <option value="20">Show 20</option>
                                                <option value="30">Show 30</option>
                                                <option value="40">Show 40</option>
                                                <option value="50">Show 50</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <input className="form-control search-box me-2 mb-2 d-inline-block" placeholder="26 records..." />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">
                                                            <div className="font-size-16 form-check">
                                                                <label className="form-check-label">
                                                                    <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">ID</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Networks</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Debit Units</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Credit Units</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">After Units</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Reason</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Status</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">User</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Created At</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Table rows */}
                                                {
                                                    orders.map(order => (
                                                        <tr key={order.id}>
                                                            <td>
                                                                <div className="cursor-pointer select-none">
                                                                    <div className="font-size-16 form-check">
                                                                        <label className="form-check-label">
                                                                            <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{order.id}</td>
                                                            <td>{order.networks}</td>
                                                            <td>{order.debitsUnits}</td>
                                                            <td>{order.creditsUnits}</td>
                                                            <td>{order.afterUnits}</td>
                                                            <td>{order.status}</td>
                                                            <td>{order.reason}</td>
                                                            <td>{order.user}</td>
                                                            <td>{order.createdAt}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody >
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info">Showing 10 of 26 Results</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled">
                                                        <a className="page-link" href="/ecommerce-orders">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active"><a className="page-link" href="/ecommerce-orders">1</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">2</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">3</a></li>
                                                    <li className="paginate_button page-item next "><a className="page-link" href="/ecommerce-orders"><i className="mdi mdi-chevron-right"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AllNetwork
