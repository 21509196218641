import React, { useEffect, useRef, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";
import { useLocation } from "react-router-dom";
import axios from "axios";

function containsBadWord(text, badWords) {
    if (!badWords || badWords.length === 0 || !text) {
        return false;
    }

    const lowerCaseText = text.toLowerCase();

    return badWords.some(word => {
        const wordString = word.keyword.toString().toLowerCase();
        return lowerCaseText.includes(wordString);
    });
}

function QuickSms() {
    document.title = "Quick SMS";
    const MAX_CONTACTS = 100;
    const [messageValue, setMessageValue] = useState('');
    const [selectedMasking, setSelectedMasking] = useState('');
    const [optionMasking, setOptionMasking] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [optionTemplate, setOptionTemplate] = useState([]);
    const [errors, setErrors] = useState({});
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const [badWords, setBadWords] = useState([]);

    const fetchBadWords = async () => {
        try {
            const TOKEN = localStorage.getItem('token')
            const response = await fetch(`${BASE_URL}/view-badwords`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBadWords(data);
        } catch (error) {
            console.error("Error fetching bad words:", error);
        }
    }
    const token = localStorage.getItem('token')

    const fetchMasking = async () => {
        try {
            const response = await fetch(`${BASE_URL}/masking`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const options = data.map(masking => ({
                value: masking.ID,
                label: masking.Mask
            }));
            setOptionMasking(options);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    const fetchSmsTemplates = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/view-template`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const data = response.data;
          console.log('API Response:', data);
          const options = data.map(template => ({
            value: template.ID,
            label: template.template_name,
            message: template.template_message
          }));
          console.log('Mapped Options:', options);
          setOptionTemplate(options);
        } catch (error) {
          console.error('Error fetching templates:', error);
        }
      };

    useEffect(() => {
        fetchBadWords();
        fetchSmsTemplates();
        fetchMasking();
    }, []);

    const handleTemplateChange = (selectedOption) => {
        setSelectedTemplate(selectedOption);
        setMessageValue(selectedOption.message);
    };

    useEffect(() => {
        if (location.state && location.state.selectedTemplateId && optionTemplate.length > 0) {
            const selectedId = location.state.selectedTemplateId;
            console.log('Selected ID:', selectedId); // Log the selected ID
            console.log('Option Templates:', optionTemplate); // Log the option templates
            const selectedTemplate = optionTemplate.find(option => option.value === selectedId);
            console.log('Selected Template:', selectedTemplate); // Log the selected template
            if (selectedTemplate) {
                setSelectedTemplate(selectedTemplate);
                setMessageValue(selectedTemplate.message);
            }
        }
    }, [location.state, optionTemplate]);

    // useEffect(() => {
    //     const selectedTemplateId = localStorage.getItem('selectedTemplateId');
    //     console.log(selectedTemplateId);
    //     if (selectedTemplateId) {
    //         const selectedOption = optionTemplate.find(option => option.value === selectedTemplateId);
    //         setSelectedTemplate(selectedOption);
    //         console.log('if ',selectedOption);
    //         localStorage.removeItem('selectedTemplateId'); 
    //     }
    // }, [optionTemplate]);


    const [smsCounter, setSmsCounter] = useState({
        encoding: "",
        length: 0,
        per_message: 0,
        remaining: 0,
        messages: 0
    });

    const [contacts, setContacts] = useState("");

    const contactHandleChange = (event) => {
        const value = event.target.value;
        const contactsArray = value.split(',').map(contact => contact.trim());

        if (contactsArray.length > 100) {
            event.preventDefault();
            return;
        }

        setContacts(value);
        setErrors({});

        const lastTenDigitsSet = new Set();

        const duplicates = contactsArray.filter(contact => {
            const lastTenDigits = contact.slice(-10);
            if (lastTenDigitsSet.has(lastTenDigits)) {
                return true;
            }
            lastTenDigitsSet.add(lastTenDigits);
            return false;
        });

        if (duplicates.length > 0) {
            setErrors({ ...errors, contacts: 'Duplicate contacts: ' + duplicates.join(', ') });
        }
    };

    const countContactLength = (value) => {
        const contactsArray = value.split(',').filter(contact => contact.trim() !== '');
        let totalCount = 0;

        contactsArray.forEach(contact => {
            const digitCount = contact.replace(/[^0-9]/g, '').length;
            if ((digitCount === 11 && contact.startsWith("0")) || (digitCount === 12 && contact.startsWith("92")) || (digitCount === 10 && contact.startsWith('3') || (digitCount === 14 && contact.startsWith("9292")))) {
                totalCount += 1;
            }
        });

        return totalCount;
    };


    useEffect(() => {
        function countSms(text) {
            const SmsCounter = {
                GSM_7BIT: 'GSM_7BIT',
                GSM_7BIT_EX: 'GSM_7BIT_EX',
                UTF16: 'UTF16',
                messageLength: {
                    GSM_7BIT: 160,
                    GSM_7BIT_EX: 160,
                    UTF16: 70
                },
                multiMessageLength: {
                    GSM_7BIT: 153,
                    GSM_7BIT_EX: 153,
                    UTF16: 67
                },
                count: function (text) {
                    let encoding = this.detectEncoding(text);
                    let length = text.length;
                    let per_message = this.messageLength[encoding];
                    if (length > per_message) {
                        per_message = this.multiMessageLength[encoding];
                    }
                    let messages = Math.ceil(length / per_message);
                    let remaining = (per_message * messages) - length;
                    return {
                        encoding: encoding,
                        length: length,
                        per_message: per_message,
                        remaining: remaining,
                        messages: messages
                    };
                },
                detectEncoding: function (text) {

                    if (text.trim() === '') {
                        return ' ';
                    }
                    // Use try-catch to handle exceptions
                    try {
                        if (text.match(RegExp("^[" + this.gsm7bitChars + "]*$", "gi"))) {
                            return this.GSM_7BIT;
                        } else if (text.match(RegExp("^[" + this.gsm7bitChars + this.gsm7bitExChar + "]*$", "gi"))) {
                            return this.GSM_7BIT_EX;
                        } else {
                            return this.UTF16;
                        }
                    } catch (error) {
                        console.error("Error detecting encoding:", error);
                        return this.GSM_7BIT;
                    }
                },
                gsm7bitChars: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
                gsm7bitExChar: "\\^{}\\\\\\[~\\]|€"
            };

            const count = SmsCounter.count(text);
            return count;
        }


        setSmsCounter(countSms(messageValue));

    }, [messageValue]);

    useEffect(() => {
        const updateMaxLength = () => {
            const textarea = document.getElementById("create-message");
            if (!textarea) return;

            switch (smsCounter.encoding) {
                case "GSM_7BIT":
                    textarea.setAttribute("maxlength", "765");
                    break;
                case "GSM_7BIT_EX":
                    textarea.setAttribute("maxlength", "763");
                    break;
                case "UTF16":
                    textarea.setAttribute("maxlength", "335");
                    break;
                default:
                    textarea.setAttribute("maxlength", "765");
                    break;
            }
        };

        updateMaxLength();
    }, [smsCounter.encoding]);

    const handleSelectMasking = (event) => {
        const selectedValue = event.target.value; // Get the selected value from the event
        setSelectedMasking(selectedValue);         // Update state
        console.log(selectedValue);                // Log the selected value
    };

    const handleChange = (e) => {
        setMessageValue(e.target.value)
        setErrors({});
    }
    useEffect(() => {
        async function fetchCsrfToken() {
            try {
                const response = await fetch('http://127.0.0.1:8000/csrf-token');
                if (response.ok) {
                    const data = await response.json();
                    setCsrfToken(data.csrf_token);
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        }
        fetchCsrfToken();
    }, []);

    const generateCampaignID = () => {
        const min = Math.pow(10, 4); // Minimum 6 digit number
        const max = Math.pow(10, 5) - 1; // Maximum 7 digit number
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min; // Generate random number within range
        return randomNumber.toString(); // Convert the number to a string
    };

    const handleSubmit = async () => {
        const errors = {};

        if (!selectedMasking) {
            errors.selectedMasking = 'Please select masking*';
        }

        if (!contacts || contacts.trim() === '') {
            errors.contacts = 'Please add at least 1 contact*';
        } else {
            // Preprocess contacts: add "92" prefix to last 10 digits
            const contactsArray = contacts.split(',').map(contact => {
                const trimmedContact = contact.trim();
                // Check if the contact already starts with "92"
                if (trimmedContact.startsWith("92")) {
                    return trimmedContact;
                }
                // Extract last 10 digits and prepend "92"
                const lastTenDigits = trimmedContact.slice(-10);
                return `92${lastTenDigits}`;
            });

            // Validate the preprocessed contacts
            const invalidContacts = contactsArray.filter(contact => !/^\+?92\d{10}$/.test(contact)); // Assuming all contacts should be in international format with "+92"
            if (invalidContacts.length > 0) {
                errors.contacts = 'Invalid phone numbers: ' + invalidContacts.join(', ');
            }

            // Check maximum number of contacts
            if (contactsArray.length > 100) {
                errors.contacts = 'Maximum 100 contacts allowed*';
            }
        }

        // Check for missing SMS message
        if (!messageValue) {
            errors.messageValue = 'Please add SMS message*';
        }


        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        const campaignID = generateCampaignID(); // generate unique group ID
        const contactsArray = contacts.split(',');
        console.log(token) // split contacts into an array

        for (const contact of contactsArray) {
            const response = await fetch(`${BASE_URL}/quick-sms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    Campaign_ID: campaignID,
                    mobile_number: "92" + contact.slice(-10),
                    message: messageValue,
                    Mask: selectedMasking,
                    encoding: smsCounter.encoding,
                    length: smsCounter.length,
                    messages: smsCounter.messages,
                    sms_type: 'Quick SMS'
                })
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setSuccessModalOpen(true)
                setErrorModalOpen(false)
                setSuccessMessage('Quick SMS created successfully')
                setErrorMessage('')
                setContacts('')
                setMessageValue('')
                setSelectedMasking('')
                setSelectedTemplate('');
            }
            else {
                setSuccessModalOpen(false)
                setErrorModalOpen(true)
                setErrorMessage(data.message)
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Sent SMS" breadcrumbItem="Quick SMS" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">Quick SMS</CardTitle>
                                    <Col lg={8}>
                                        <p className="card-title-desc">
                                            Quick SMS is designed to simplify the process of sending short messages swiftly. With Quick SMS, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                        </p>
                                    </Col>
                                    <Form className="outer-repeater" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={5}>
                                                <div data-repeater-list="outer-group" className="outer">
                                                    <div data-repeater-item className="outer">
                                                        <div className="mb-3">
                                                            <Label htmlFor="formname">Select Masking : </Label>
                                                            <select name="Mask" className="form-select" value={selectedMasking} onChange={handleSelectMasking}>
                                                                <option value='' disabled defaultValue={'Select....'}>Select Masking</option>
                                                                {optionMasking.map((option) => (
                                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                            {errors.selectedMasking && <p className="mt-1 text-danger" >{errors.selectedMasking}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={7}>
                                                <div data-repeater-list="outer-group" className="outer">
                                                    <div data-repeater-item className="outer">
                                                        <div className="mb-3">
                                                            <Label>Choose SMS Template</Label>
                                                            <Select
                                                                value={selectedTemplate}
                                                                onChange={handleTemplateChange}
                                                                options={optionTemplate}
                                                                className="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className="mb-3">
                                                    <Label htmlFor="formmessage" className="mb-2 mt-2">Add Contact Numbers :</Label>
                                                    <Input
                                                        type="textarea"
                                                        id="formmessage"
                                                        className="form-control"
                                                        rows="10"
                                                        placeholder="Add your Numbers"
                                                        value={contacts}
                                                        onChange={contactHandleChange}
                                                        name='mobile_number'
                                                    />
                                                    {errors.contacts && <p className="mt-1 text-danger" >{errors.contacts}</p>}
                                                    <ul className="btn btn-secondary btn-soft-info d-flex justify-content-between list-unstyled rounded waves-effect waves-light my-3">
                                                        <li>
                                                            Contact : {countContactLength(contacts)}
                                                        </li>
                                                        <li>
                                                            Max Contact: 100
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col lg={7}>

                                                <div className="mb-3">
                                                    <div className="d-flex justify-content-between align-items-center mb-2">

                                                        <Label htmlFor="message" className="mb-0">Create SMS Template</Label>
                                                        <small className={`btn btn-sm btn-soft-${smsCounter.encoding === 'GSM_7BIT' ? 'info' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'primary' : 'danger'}`}>
                                                            {smsCounter.encoding === 'GSM_7BIT' ? 'MAX: 765' : smsCounter.encoding === 'GSM_7BIT_EX' ? 'MAX: 755' : 'MAX: 355'} Characters ({smsCounter.encoding})
                                                        </small>
                                                    </div>
                                                    <Input
                                                        type="textarea"
                                                        rows={10}
                                                        id="create-message"
                                                        name='message'
                                                        className={`form-control ${errors.smsTemplate ? 'is-invalid' : ''}`}
                                                        placeholder="Enter Your Message"
                                                        value={messageValue}
                                                        onChange={e => setMessageValue(e.target.value)}
                                                        maxLength={smsCounter.encoding === "GSM_7BIT" ? 765 : smsCounter.encoding === "GSM_7BIT_EX" ? 763 : 335}
                                                    />
                                                    {errors.smsTemplate && <div className="invalid-feedback">{errors.smsTemplate}</div>}
                                                    {errors.messageValue && <div className="mt-1 text-danger" >{errors.messageValue}</div>}
                                                </div>

                                                <ul className=" btn btn-soft-danger waves-effect waves-light btn btn-secondary list-unstyled d-flex justify-content-between rounded">
                                                    <li>Message length: {smsCounter.length}</li>
                                                    <li>No. of Messages: {smsCounter.messages}</li>
                                                </ul>
                                                <div className="mt-5 text-end">
                                                    <Button type='button' color="btn btn-success w-md" onClick={handleSubmit}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                    <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                        Success
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-checkmark h1 text-success"></i>
                        <p className="mt-3">{successMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleSuccessModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                    <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                        Error
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i className="dripicons-wrong h1 text-danger"></i>
                        <p className="mt-3">{errorMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleErrorModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment >
    )
}

export default QuickSms;
