import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Alert,
    ModalFooter,
} from "reactstrap";
import Select from "react-select";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";
import TableContainer from "components/Common/TableContainer";

function ViewBadwordsKeys() {
    document.title = "View Badwords Keys";

    const [modalData, setModalData] = useState({
        ID: '',
        keyword: '',
        group_name: '',
        user: '',
        created_at: ''
    });
    const [orders, setOrders] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [editingRowData, setEditingRowData] = useState(null);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await fetch(`${BASE_URL}/view-badwords`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch groups');
                }
                const data = await response.json();
                const options = data.map(group => ({ value: group.ID, label: group.group_name }));
                setGroupOptions(options);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []);


    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleEditClick = (rowData) => {
        setModalData(rowData);
        toggleEditModal();
    };

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setModalData(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // For regular input fields
        if (name !== 'group_name') {
            setModalData(prevData => ({
                ...prevData,
                [name]: value
            }));
        } else {
            // For react-select component
            setModalData(prevData => ({
                ...prevData,
                group_name: {
                    value: value,
                    label: value // Assuming value and label are the same for group_name
                }
            }));
        }
    };


    const token = localStorage.getItem('token')

    const updateBadwordsKeyData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/view-badwords`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const id = modalData.ID;
            const response = await fetch(`${BASE_URL}/badwords/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    keyword: modalData.keyword,
                    group_name: modalData.group_id,
                }),
            });
            const responseData = await response.json();
            if (!response.ok) {
                // throw new Error(responseData.message || 'Failed to update template.');
                if (response.status === 400) {
                    setErrorMessage(responseData.error)
                    setSuccessMessage('');
                    setSuccessModalOpen(false);
                    setErrorModalOpen(true);
                    updateBadwordsKeyData();
                }
                setSuccessMessage('');
                setErrorMessage(responseData.errors);
                setSuccessModalOpen(false);
                setErrorModalOpen(true);
                updateBadwordsKeyData();
            }
            setSuccessMessage(responseData.message);
            setErrorMessage('');
            setSuccessModalOpen(true);
            setErrorModalOpen(false);
            updateBadwordsKeyData();
            toggleEditModal();
        } catch (error) {
            console.error('Fetch request error:', error);
            setErrorMessage('Failed to update template. Please try again.');
            setSuccessMessage('');
            setSuccessModalOpen(false);
            setErrorModalOpen(true);
        }
    };

    const toggleSuccessModal = () => {
        setSuccessMessage('');
        setSuccessModalOpen(!successModalOpen);
    };

    const toggleErrorModal = () => {
        setErrorMessage('');
        setErrorModalOpen(!errorModalOpen);
    };


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/view-badwords`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchOrders();
    }, []);

    const handleDeleteClick = (rowData) => {
        if (rowData && rowData.ID) {
            setEditingRowData(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };

    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/badwords/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.ID }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.ID !== rowData.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
            } else {
                console.error('Failed to delete contact group');
            }
        } catch (error) {
            console.error('Error deleting contact group:', error);
        }
    };

    const [deleteModal, setDeleteModal] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'ID', disableFilters: true, },
        { Header: 'Keyword', accessor: 'keyword', disableFilters: true, },
        { Header: 'Group', accessor: 'group_name', disableFilters: true, },
        { Header: 'User', accessor: 'name', disableFilters: true, },
        { Header: 'Creation At', accessor: 'created_time', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Contacts" breadcrumbItem="Manage Contact Groups" />
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            Edit SMS Template
                        </ModalHeader>
                        <ModalBody>
                            <Label>Keyword</Label>
                            <Input value={modalData.keyword} name="keyword" className="mb-3" onChange={handleInputChange} />
                            <div className="mb-3">
                                <Label>Select Group</Label>
                                <select name="group_name" className="form-control" value={modalData.group_name} onChange={handleInputChange}>
                                    {groupOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            {successMessage && <Alert color="success">{successMessage}</Alert>}
                            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        </ModalBody>
                        <div className="modal-footer">
                            <Button color="secondary" onClick={handleSubmit}>Update Value</Button>
                        </div>
                    </Modal>
                    <SuccessModal
                        isOpen={successModalOpen}
                        toggle={toggleSuccessModal}
                        successMessage={successMessage}
                    />
                    <ErrorModal
                        isOpen={errorModalOpen}
                        toggle={toggleErrorModal}
                        errorMessage={errorMessage}
                    />
                    <DeleteModal
                        backdrop="static"
                        isOpen={deleteModal}
                        toggle={() => setDeleteModal(false)}
                        handleDeleteOrder={handleDeleteOrder}
                        rowData={editingRowData}
                        value={'SMS Template'}
                    />

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/add-badwords'}
                                        linkValue={'Add New Badword'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ViewBadwordsKeys;
