import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table
} from "reactstrap";

function BulkSmsReport() {
    document.title = "Bulk SMS Reports";

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        name: '',
        contactNumber: '',
        masking: '',
        message: '',
        postedBy: '',
        createdAt: '',
        cost: ''
    });
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([
        {
            id: 1,
            contactNumber: '0300-1234567',
            masking: 'IISOL-DEMO',
            message: 'This is the test',
            unicode: 'No',
            length: '17/2',
            user: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm',
            cost: '$38'
        },
        {
            id: 2,
            contactNumber: '92300-1234567',
            masking: 'IISOL-PORTAL',
            message: `Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum`,
            unicode: 'Yes',
            length: '20/2',
            user: 'iisol_testing',
            createdAt: '12/29/23 03:40:35 pm',
            cost: '$120'
        }
    ]);


    const [editModal, setEditModal] = useState(false); 

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };


    const handleEditClick = (rowData) => {
        setEditingRowData(rowData);
        setModalData(rowData); // Pre-fill modalData with the selected contact message data
        toggleEditModal(); // Open edit modal
    };

    return (
        <React.Fragment>
            {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Bulk SMS" />
                    {/* <Row>
                        <Col xs="12">
                        <Card>
                            <CardBody>
                            <TableContainer
                                columns={columns}
                                data={[]}
                                isGlobalFilter={true}
                                isAddOptions={true}
                                handleOrderClicks={handleOrderClicks}
                                customPageSize={10}
                            />
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>*/}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            {!!editingRowData ? "Report Detail" : "Add Order"}
                        </ModalHeader>
                        <div className="modal-body">

                            <h4 className="mb-2">
                                Contact ID: <span className="text-primary">{modalData.id}</span>
                            </h4>

                            <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>Product</th>
                                            <th scope="col" colSpan={2}>Product Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Mobile Number:</h6>
                                            </td>
                                            <td>
                                                {modalData.contactNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Message:</h6>
                                            </td>
                                            <td style={{ whiteSpace: 'normal' }}>
                                                {modalData.message}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Created at:</h6>
                                            </td>
                                            <td>
                                                {modalData.createdAt}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button color="danger" onClick={handleEditClick}>Done</Button>
                        </div>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2 row">
                                        {/* <div className="col-sm-2">
                                            <select className="form-select pageSize mb-2">
                                                <option value="10">Show 10</option>
                                                <option value="20">Show 20</option>
                                                <option value="30">Show 30</option>
                                                <option value="40">Show 40</option>
                                                <option value="50">Show 50</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <input className="form-control search-box me-2 mb-2 d-inline-block" placeholder="26 records..." />
                                        </div> */}
                                        <div className="col-sm-6">
                                            <div className="text-sm-end">

                                                {/* <Link
                                                    to={'/quick-sms'}
                                                    type="button"
                                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addOrder-modal btn btn-secondary"
                                                >
                                                    <i className="mdi mdi-plus me-1"></i> Add Bulk SMS
                                                </Link> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline table table-hover">
                                            {/* <thead className="table-light">
                                                <tr>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">
                                                            <div className="font-size-16 form-check">
                                                                <label className="form-check-label">
                                                                    <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">ID</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Contact Number</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Masking</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Message</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Unicode</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Length</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">User</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Created At</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Status</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Cost</div>
                                                    </th>
                                                    <th colSpan="1" className="sorting sorting_desc">
                                                        <div className="cursor-pointer select-none">Action</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orders.map(order => (
                                                        <tr key={order.id}>
                                                            <td>
                                                                <div className="cursor-pointer select-none">
                                                                    <div className="font-size-16 form-check">
                                                                        <label className="form-check-label">
                                                                            <input id="checkAll" type="checkbox" className="form-check-input" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{order.id}</td>
                                                            <td>{order.contactNumber}</td>
                                                            <td>{order.masking}</td>
                                                            <td style={{ width: '40px', maxWidth: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{order.message}</td>
                                                            <td>{order.unicode}</td>
                                                            <td>{order.length}</td>
                                                            <td>{order.user}</td>
                                                            <td>{order.createdAt}</td>
                                                            <td><Badge className="bg-success">Approved</Badge></td>
                                                            <td>{order.cost}</td>

                                                            <td className="justify-content-evenly d-flex">
                                                                <Link
                                                                    className="btn btn-sm btn-soft-primary"
                                                                    onClick={() => {
                                                                        handleEditClick(order);
                                                                        toggleEditModal(); // Call toggleEditModal to open the edit modal
                                                                    }}
                                                                >
                                                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                                                                    <UncontrolledTooltip placement="top" target="viewtooltip">
                                                                        View
                                                                    </UncontrolledTooltip>
                                                                </Link>


                                                                <Link
                                                                    className="btn btn-sm btn-soft-danger"
                                                                    onClick={() => handleDeleteClick(order)}
                                                                >
                                                                    <i className="mdi mdi-delete-outline font-size-18" id="deletetooltip" />
                                                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                        Delete
                                                                    </UncontrolledTooltip>
                                                                </Link>

                                                                <Modal size="sm" isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
                                                                    <div className="modal-content">
                                                                        <ModalBody className="px-4 py-5 text-center">
                                                                            <button type="button" onClick={handleDeleteOrder} className="btn-close position-absolute end-0 top-0 m-3"></button>
                                                                            <div className="avatar-sm mb-4 mx-auto">
                                                                                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                                                                    <i className="mdi mdi-trash-can-outline"></i>
                                                                                </div>
                                                                            </div>
                                                                            <p className="text-muted font-size-16 mb-4">Are you sure you want to permanently delete this Contact detail?</p>

                                                                            <div className="hstack gap-2 justify-content-center mb-0">
                                                                                <button type="button" className="btn btn-danger" onClick={handleDeleteOrder}>Delete Now</button>
                                                                                <button type="button" className="btn btn-secondary" onClick={() => setDeleteModal(false)}>Close</button>
                                                                            </div>
                                                                        </ModalBody>
                                                                    </div>
                                                                </Modal> 

                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody > */}
                                            <div className="alert bg-info m-5 mb-0 text-white">
                                                Record Not Found !
                                            </div>
                                        </table>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-12 col-md-5">
                                            <div className="dataTables_info">Showing 10 of 26 Results</div>
                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                            <div className="dataTables_paginate paging_simple_numbers pagination-rounded">
                                                <ul className="pagination">
                                                    <li className="paginate_button page-item previous disabled">
                                                        <a className="page-link" href="/ecommerce-orders">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="paginate_button page-item active"><a className="page-link" href="/ecommerce-orders">1</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">2</a></li>
                                                    <li className="paginate_button page-item "><a className="page-link" href="/ecommerce-orders">3</a></li>
                                                    <li className="paginate_button page-item next "><a className="page-link" href="/ecommerce-orders"><i className="mdi mdi-chevron-right"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BulkSmsReport