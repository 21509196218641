import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import BASE_URL from "Config/config";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
        window.location.reload();
      } else {
        console.log('Error:', data.message || 'Failed to logout');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Admin")} </li>

            <li>
              <Link to="/dashboard"><i className="mdi mdi-home"></i> {props.t("Admin Dashboard")}</Link>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-envelope"></i>
                <span>{props.t("Masking")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/view-masking-service-provider">{props.t("Manage Service Provider")}</Link>
                </li>
                <li>
                  <Link to="/add-masking">{props.t("New Masking")}</Link>
                </li>
                <li>
                  <Link to="/view-masking-directory">{props.t("Masking Directory")}</Link>
                </li>
                <li>
                  <Link to="/view-masking-request">{props.t("Masking Request")}</Link>
                </li>
                <li>
                  <Link to="/add-masking-allocation">{props.t("Masking Allocation")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="mdi mdi-hospital-building"></i>
                <span>{props.t("Company")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-company">{props.t("Add Company")}</Link>
                </li>
                <li>
                  <Link to="/view-company-details">{props.t("View Company")}</Link>
                </li>
              </ul>
            </li>
            
            <li>
              <Link to="/#" >
                <i className="mdi mdi-account-group"></i>
                <span>{props.t("Company Users")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-company-users">{props.t("Add Company Users")}</Link>
                </li>
                <li>
                  <Link to="/view-company-users-details">{props.t("View Company Users")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bx-list-plus"></i>
                <span>{props.t("Built-in SMS Templates")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-builtin-template">{props.t("Add Builtin Template")}</Link>
                </li>
                <li>
                  <Link to="/view-builtin-sms-template">{props.t("View Builtin Template")}</Link>
                </li>
              </ul>
            </li>
            
            <li>
              <Link to="/#" >
                <i className="mdi mdi-server-network"></i>
                <span>{props.t("IP Module")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-ip">{props.t("Add IP")}</Link>
                </li>
                <li>
                  <Link to="/view-builtin-sms-template">{props.t("View IPs")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("SMS Module")}</li>

            <li>
              <Link to="/#" >
                <i className="mdi mdi-comment-edit-outline"></i>
                <span>{props.t("SMS Templates")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-template">{props.t("Add SMS Template")}</Link>
                </li>
                <li>
                  <Link to="/view-sms-template">{props.t("My SMS Templates")}</Link>
                </li>
                <li>
                  <Link to="/view-builtin-template">{props.t("Builtin SMS Templates")}</Link>
                </li>
              </ul>

            </li>

            <li>
              <Link to="/#" >
                <i className="mdi mdi-comment-multiple-outline"></i>
                <span>{props.t("Sent SMS")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/quick-sms">{props.t("Quick SMS")}</Link>
                </li>
                <li>
                  <Link to="/add-sms-campaign">{props.t("Campaign SMS")}</Link>
                </li>
                <li>
                  <Link to="/add-personalize-sms">{props.t("Personalized SMS")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bx-crosshair"></i>
                <span>{props.t("Bad Words")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-badwords">{props.t("Add Badword")}</Link>
                </li>
                <li>
                  <Link to="/view-badwords-key">{props.t("View Badword")}</Link>
                </li>
                <li>
                  <Link to="/view-badwords-group">{props.t("Manage Badword Groups")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-contact">{props.t("Add New Contacts")}</Link>
                </li>
                <li>
                  <Link to="/contacts">{props.t("Manage Contacts")}</Link>
                </li>
                <li>
                  <Link to="/contact-groups">{props.t("Manage Groups")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Reports/quick-sms">{props.t("Quick SMS")}</Link>
                </li>
                <li>
                  <Link to="/Reports/bulk-sms">{props.t("Bulk SMS")}</Link>
                </li>
                <li>
                  <Link to="/Reports/campaign-sms">{props.t("Campaign SMS")}</Link>
                </li>
                <li>
                  <Link to="/Reports/personalized-sms">{props.t("Personalized SMS")}</Link>
                </li>
                <li>
                  <Link to="/Reports/api-sms">{props.t("API SMS")}</Link>
                </li>
                <li>
                  <Link to="/Reports/activity-logs">{props.t("Logs Report")}</Link>
                </li>
                <li>
                  <Link to="/Reports/balance-reports">{props.t("Balance Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="mdi mdi-file-key-outline"></i>
                <span>{props.t("Key API")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-api-key">{props.t("New Key API")}</Link>
                </li>
                <li>
                  <Link to="/view-api-key">{props.t("View Key API")}</Link>
                </li>
                <li>
                  <Link to="/api-key-docs">{props.t("View Key Docs")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Sign Out")} </li>
            <li>
              <Link to="/#" >
                <i className="bx bx-power-off"></i>
                <span onClick={handleLogout} >{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
