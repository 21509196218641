import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";

function AddMaskingRequest() {
    document.title = "Add Masking Requests";

    const [selectedMasking, setSelectedMasking] = useState('');
    const [optionMasking, setOptionMasking] = useState([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState('');
    const [optionServiceProvider, setOptionServiceProvider] = useState([]);

    const handleSelectMasking = (event) => {
        const selectedValue = event.target.value;
        setSelectedMasking(selectedValue);       
        console.log(selectedValue);              
    };
   
    const handleSelectServiceProvider = (event) => {
        const selectedValue = event.target.value;
        setSelectedServiceProvider(selectedValue);       
        console.log(selectedValue);              
    };

    const token = localStorage.getItem('token');

    const fetchMasking = async () => {
        try {
            const response = await fetch(`${BASE_URL}/masking`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const options = data.map(masking => ({
                value: masking.ID,
                label: masking.Mask
            }));
            setOptionMasking(options);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    
    const fetchServiceProvider = async () => {
        try {
            const response = await fetch(`${BASE_URL}/service-provider`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const options = data.map(serviceProvider => ({
                value: serviceProvider.MaskingServiceProvider_ID,
                label: serviceProvider.MaskingServiceProvider_Name
            }));
            setOptionServiceProvider(options);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchMasking();
        fetchServiceProvider();
    }, []);

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${BASE_URL}/campaign-sms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    campaign_name: campaign_name,
                    message: messageValue,
                    Mask: selectedMasking,
                    encoding: smsCounter.encoding,
                    length: smsCounter.length,
                    messages: smsCounter.messages,
                    contact: contacts,
                })
            });
            if (!response.ok) {
                const data = await response.json();
                setErrorMessage(data.message);
                setErrorModalOpen(true);
                setSuccessModalOpen(false);
                return
            }

            const responseData = await response.json();
            setSuccessMessage(responseData.message);
            setSuccessModalOpen(true);
            setErrorModalOpen(false);

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || "Campaign created successfully!");
                setSuccessModalOpen(true);
            } else {
                setErrorMessage(data.message);
                console.log(data.message);
                setErrorModalOpen(true);
            }
        } catch (error) {
            console.error('Error adding campaign:', error);
        }
    }

    const handleReset = () => {
        setOptionMasking('');
        // ('');
    }

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Masking" breadcrumbItem="Add Masking Request" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <CardTitle className="h4">Add Masking Request</CardTitle>
                                            <Col lg={12}>
                                                <p className="card-title-desc">
                                                    Masking is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                </p>
                                            </Col>
                                            <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div data-repeater-list="outer-group" className="outer">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Masking Name : </Label>
                                                                        <select name="Mask" className="form-select" value={selectedMasking} onChange={handleSelectMasking}>
                                                                            <option value='' disabled defaultValue={'Select....'}>Select Masking</option>
                                                                            {optionMasking.map((option) => (
                                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formname">Service Provider Name : </Label>
                                                                        <select name="Mask" className="form-select" value={selectedServiceProvider} onChange={handleSelectServiceProvider}>
                                                                            <option value='' disabled defaultValue={'Select....'}>Select Service Provider</option>
                                                                            {optionServiceProvider.map((option) => (
                                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="d-flex justify-content-end gap-2 mt-4">
                                                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <SuccessModal
                    isOpen={successModalOpen}
                    toggle={toggleSuccessModal}
                    successMessage={successMessage}
                />
                <ErrorModal
                    isOpen={errorModalOpen}
                    toggle={toggleErrorModal}
                    errorMessage={errorMessage}
                />
            </div>
        </React.Fragment>
    )
}

export default AddMaskingRequest;
