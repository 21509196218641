// ErrorModal.jsx
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ErrorModal({ isOpen, toggle, errorMessage }) {
    return (
        <Modal isOpen={isOpen} size="sm" toggle={toggle} centered>
            <ModalHeader toggle={toggle} className="bg-danger text-white">
                Error
            </ModalHeader>
            <ModalBody className="text-center">
                <i className="dripicons-wrong h1 text-danger"></i>
                <p className="mt-3">{errorMessage}</p>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-light w-100"
                >
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default ErrorModal;
