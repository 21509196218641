// SuccessModal.jsx
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function SuccessModal({ isOpen, toggle, successMessage }) {
    return (
        <Modal isOpen={isOpen} size="sm" toggle={toggle} centered>
            <ModalHeader toggle={toggle} className="bg-success text-white">
                Success
            </ModalHeader>
            <ModalBody className="text-center">
                <i className="dripicons-checkmark h1 text-success"></i>
                <p className="mt-3">{successMessage}</p>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-light w-100"
                >
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default SuccessModal;
