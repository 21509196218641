import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    UncontrolledTooltip,
    ModalFooter,
    Badge,
} from "reactstrap";
import BASE_URL from "Config/config";
import Select from "react-select";
import TableContainer from "components/Common/TableContainer";

function ViewCompanyUsers() {
    document.title = "View Company Users";

    const [modalData, setModalData] = useState({
        ID: '',
        Mask: '',
        Company_ID: '',
        created_it: '',
        status: '',
    });
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedMasking, setSelectedMasking] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    const toggleEditModal = () => setEditModal(!editModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const token = localStorage.getItem('token')

    const handleEditClick = (rowData) => {
        setModalData(rowData.original);
        toggleEditModal();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setModalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        try {
            const response = await fetch(`${BASE_URL}/companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                const companyData = data.map((company) => ({
                    label: company.name,
                    value: company.id
                }));
                setCompanies(companyData);
            }
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/company-users`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [token]);

    const getUpdatedData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/company-users`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOrders(data.map(companyUser => ({
                ...companyUser,
                Account_Name: companyUser.Account_Name
            })));
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    const handleSaveModal = async () => {
        try {
            const id = modalData.ID;
            console.log(id);
            const response = await fetch(`${BASE_URL}/masking/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    Mask: modalData.Mask,
                    Company_ID: modalData.Company_ID
                }),
            });
            if (response.ok) {
                getUpdatedData();
                setSuccessMessage('Company data updated successfully!');
                setSuccessModalOpen(true);
                setOrders(prevOrders => prevOrders.map(order =>
                    order.id === modalData.ID ? { ...order, ...modalData } : order));
                toggleEditModal();
                console.log(modalData);
            }
            else {
                console.error('Failed to update Company data');
                setErrorMessage('Error updating Company data: ' + response.statusText);
                setErrorModalOpen(true);
            }
        } catch (error) {
            console.error('Error updating Company data:', error);
        }
    };

    const handleDeleteClick = (rowData) => {
        if (rowData.original && rowData.original.ID) {
            setEditingRowData(rowData.original);
            console.log(rowData);
            toggleDeleteModal();
        } else {
            console.error('Row data is null or id is not defined');
        }
    };


    const handleDeleteOrder = async (rowData) => {
        try {
            const id = rowData.original.ID;
            console.log(id);

            const response = await fetch(`${BASE_URL}/masking/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: rowData.original.ID }),
            });
            if (response.ok) {
                const updatedOrders = orders.filter(order => order.ID !== rowData.original.ID);
                setOrders(updatedOrders);
                setDeleteModal(false);
                console.log(rowData.original);
            } else {
                console.error('Failed to delete Company data');
            }
        } catch (error) {
            console.error('Error deleting Company data:', error);
        }
    };

    const handleSelectMasking = (selectedOption) => {
        setSelectedMasking(selectedOption);
        setModalData(prevData => ({
            ...prevData,
            Company_ID: selectedOption.value
        }));
    };

    const options = [
        { value: '1', label: 'Active', icon: <i className="bx bx-check-circle text-success"></i> },
        { value: '2', label: 'Inactive', icon: <i className="bx bx-x-circle text-danger"></i> }
    ];

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'company_users_id', disableFilters: true },
        { Header: 'User Name', accessor: 'User_Name', disableFilters: true },
        { Header: 'Company', accessor: 'company_name', disableFilters: true },
        { Header: 'Balance', accessor: 'total', disableFilters: true },
        { Header: 'New Balance', accessor: 'user_id', disableFilters: true },
        { Header: 'Mask', accessor: 'Mask', disableFilters: true },
        {
            Header: 'Cell No',
            accessor: 'User_Cell',
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
                const values = value ? value.split(', ') : [];
                return (
                    <>
                        {values.map((value, index) => (
                            <span key={index} color="info" className="p-1 btn-soft-info d-block text-center my-2">
                                {value}
                            </span>
                        ))}
                    </>
                );
            }
        },
        {
            Header: 'Email',
            accessor: 'User_Email',
            disableFilters: true,
            Cell: ({ cell: { value } }) => {
                const values = value ? value.split(', ') : [];
                return (
                    <div>
                        {values.map((value, index) => (
                            <span key={index} className="p-1 btn-soft-info d-block text-center my-2">
                                {value}
                            </span>
                        ))}
                    </div>
                );
            }
        },
        { Header: 'Expiry Date', accessor: 'User_Account_Expiry', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Company" breadcrumbItem="Manage Company Users" />
                    {/* Edit Modal */}
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>Edit Company </ModalHeader>
                        <ModalBody>

                            <Label>Category Name</Label>
                            <Input value={modalData.Mask} name="Mask" className="mb-3" onChange={handleInputChange} />

                            <Label>Company</Label>

                            <Select
                                options={companies}
                                value={companies.find(company => company.value === modalData.Company_ID)}
                                name="Company_ID"
                                onChange={handleSelectMasking}
                            />

                            <div className="modal-footer">
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" onClick={handleSaveModal}>Save</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActions={true}
                                        linkURL={'/add-company-users'}
                                        linkValue={'Add Company Users'}
                                        handleDelete={handleDeleteClick}
                                        handleEditClick={handleEditClick}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                    Success
                </ModalHeader>
                <ModalBody className="text-center">
                    <i className="dripicons-checkmark h3 text-success"></i>
                    <p className="mt-3">{successMessage}</p>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        onClick={toggleSuccessModal}
                        className="btn btn-light w-100"
                    >
                        Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                    Error
                </ModalHeader>
                <ModalBody className="text-center">
                    <i className="dripicons-wrong h1 text-danger"></i>
                    <p className="mt-3">{errorMessage}</p>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        onClick={toggleErrorModal}
                        className="btn btn-light w-100"
                    >
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default ViewCompanyUsers 