import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Button,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Badge,
    Table
} from "reactstrap";
import BASE_URL from "Config/config";
import TableContainer from "components/Common/TableContainer";

function CampaignSmsReport() {
    document.title = "Campaign SMS Reports";

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        name: '',
        contacts: '',
        masking: '',
        message: '',
        postedBy: '',
        created_it: '',
        cost: ''
    });
    const [editingRowData, setEditingRowData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [editModal, setEditModal] = useState(false);

    const toggleEditModal = () => {
        setEditModal(!editModal);
    };

    const token = localStorage.getItem('token')


    useEffect(() => {
        const fetchCampaignSmsData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/campaign-sms`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setOrders(data);
            } catch (error) {
                console.error('Error fetching campaign sms data:', error);
            }
        };
        fetchCampaignSmsData();
    }, [])

    const handleEditClick = (rowData) => {
        setEditingRowData(rowData);
        setModalData(rowData); // Pre-fill modalData with the selected contact message data
        toggleEditModal(); // Open edit modal
    };

    const handleDownloadHistory = (rowData) => {
        const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(`ID, Campaign Name, Masking, Total, Error, Sent, Remaining, Cost, User, Created At, Run Time\n${Object.values(rowData).join(',')}`);
        
        // Create anchor element for downloading
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        link.setAttribute("download", `personalize-sms history_${rowData.id}.csv`);
        document.body.appendChild(link);
        
        // Trigger download
        link.click();
    
        // Cleanup
        document.body.removeChild(link);
    };

    const columns = useMemo(() => [
        { Header: 'Id', accessor: 'CampaignID', disableFilters: true, },
        { Header: 'Campaign Name', accessor: 'campaign_name', disableFilters: true, },
        { Header: 'Masking', accessor: 'Mask', disableFilters: true, },
        { Header: 'Total SMS', accessor: 'totalSMS', disableFilters: true, },
        { Header: 'Error', accessor: 'totalErrorSMS', disableFilters: true, },
        { Header: 'Sent', accessor: 'totalSentSMS', disableFilters: true, },
        { Header: 'Remaining', accessor: 'totalErrorSMS', id: 'remaining' , disableFilters: true, },
        { Header: 'Cost', accessor: 'cost', disableFilters: true, },
        { Header: 'Status', accessor: 'status', disableFilters: true, },
        { Header: 'User', accessor: 'name', disableFilters: true, },
        { Header: 'Created At', accessor: 'created_it', disableFilters: true, },
        { Header: 'Run Time', accessor: 'created_it',id: 'run_time', disableFilters: true, },
    ], []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Campaign SMS" />
                    <Modal isOpen={editModal} toggle={toggleEditModal}>
                        <ModalHeader toggle={toggleEditModal}>
                            {!!editingRowData ? "Report Detail" : "Add Order"}
                        </ModalHeader>
                        <div className="modal-body">

                            <h4 className="mb-2">
                                Contact ID: <span className="text-primary">{modalData.id}</span>
                            </h4>

                            <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>Product</th>
                                            <th scope="col" colSpan={2}>Product Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Mobile Number:</h6>
                                            </td>
                                            <td>
                                                {modalData.contacts}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Message:</h6>
                                            </td>
                                            <td style={{ whiteSpace: 'normal' }}>
                                                {modalData.message}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <h6 className="m-0 text-right">Created at:</h6>
                                            </td>
                                            <td>
                                                {modalData.created_it}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button color="danger" onClick={handleEditClick}>Done</Button>
                        </div>
                    </Modal>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <TableContainer
                                        columns={columns}
                                        data={orders.map((data, index) => ({ ...data, key: index }))}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        linkURL={'/quick-sms'}
                                        linkValue={'Add Quick SMS'}
                                        customPageSize={10}
                                        isPagination={true}
                                        isActionHistory={true}
                                        handleDownloadData={handleDownloadHistory}
                                        className="table-responsive"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CampaignSmsReport