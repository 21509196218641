import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Badge,
  Table,
} from "reactstrap";
import CheckboxRow from "../../../components/MyComponents/CheckboxRow";
import DeleteModal from "components/MyComponents/DeleteModal";
import BASE_URL from "Config/config";
import NoTableDataFound from "components/MyComponents/NoTableDataFound";
import CustomStatusSelect from "components/MyComponents/CustomStatusSelect";
import SuccessModal from "components/MyComponents/SuccessModal";
import ErrorModal from "components/MyComponents/ErrorModal";
import TableContainer from "components/Common/TableContainer";

function ViewBuiltinTemplate() {
  document.title = "View SMS Template";

  const [modalData, setModalData] = useState({
    ID: '',
    template_name: '',
    template_message: '',
    sms_encoding: '',
    sms_template_length: '',
    created_time: ''
  });
  const [orders, setOrders] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [status, setStatus] = useState('inactive');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [smsCounter, setSmsCounter] = useState({
    sms_encoding: '',
    sms_template_length: 0,
    per_message: 0,
    remaining: 0,
    template_message: 0
  });
  const [editingRowData, setEditingRowData] = useState(null);
  const editModalMessage = useRef(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
  const toggleEditModal = () => setEditModal(!editModal);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const handleEditClick = (rowData) => {
    setModalData(rowData.original);
    toggleEditModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setModalData(prevData => ({
      ...prevData,
      [name]: value
    }));

    const count = countSms(value);
    setSmsCounter(count);
  };


  function countSms(text) {
    const SmsCounter = {
      GSM_7BIT: 'GSM_7BIT',
      GSM_7BIT_EX: 'GSM_7BIT_EX',
      UTF16: 'UTF16',
      messageLength: {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UTF16: 70
      },
      multiMessageLength: {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UTF16: 67
      },
      count: function (text) {
        let encoding = this.detectEncoding(text);
        let length = text.length;
        let per_message = this.messageLength[encoding];
        if (length > per_message) {
          per_message = this.multiMessageLength[encoding];
        }
        let messages = Math.ceil(length / per_message);
        let remaining = (per_message * messages) - length;
        return {
          sms_encoding: encoding,
          sms_template_length: length,
          per_message: per_message,
          remaining: remaining,
          messages: messages
        };
      },
      detectEncoding: function (text) {

        if (text.trim() === '') {
          return ' ';
        }
        // Use try-catch to handle exceptions
        try {
          if (text.match(RegExp("^[" + this.gsm7bitChars + "]*$", "gi"))) {
            return this.GSM_7BIT;
          } else if (text.match(RegExp("^[" + this.gsm7bitChars + this.gsm7bitExChar + "]*$", "gi"))) {
            return this.GSM_7BIT_EX;
          } else {
            return this.UTF16;
          }
        } catch (error) {
          console.error("Error detecting encoding:", error);
          return this.GSM_7BIT;
        }
      },
      gsm7bitChars: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
      gsm7bitExChar: "\\^{}\\\\\\[~\\]|€"
    };

    const count = SmsCounter.count(text);
    return count;
  }
  useEffect(() => {
    setSmsCounter(countSms(modalData.template_message));
  }, [modalData.template_message]);

  useEffect(() => {
    const updateMaxLength = () => {
      const textarea = editModalMessage.current;
      if (!textarea) return;

      switch (smsCounter.encoding) {
        case "GSM_7BIT":
          textarea.setAttribute("maxlength", "765");
          break;
        case "GSM_7BIT_EX":
          textarea.setAttribute("maxlength", "763");
          break;
        case "UTF16":
          textarea.setAttribute("maxlength", "335");
          break;
        default:
          textarea.setAttribute("maxlength", "765");
          break;
      }
    };

    updateMaxLength();
  }, [modalData.sms_encoding]);

  const token = localStorage.getItem('token');

  // update 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const ID = modalData.ID;
      console.log('modal id', ID)
      const messageLength = modalData.template_message.length;
      const perMessage = smsCounter.sms_encoding === "GSM_7BIT" ? 160 : 70;
      const messageNumber = Math.ceil(messageLength / perMessage);

      if (!modalData.template_name.trim()) {
        setErrorMessage('Template name cannot be empty'); 
        return;
      }
      const updatedModalData = {
        templateName: modalData.template_name,
        smsTemplate: modalData.template_message,
        encoding: smsCounter.sms_encoding,
        message_length: messageLength,
        message_number: messageNumber,
        sms_template_status: status
      };
      const response = await fetch(`${BASE_URL}/create-template`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedModalData),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSuccessMessage(data.message);
        setErrorMessage('');
        setSuccessModalOpen(true);
        setOrders(prevOrders => {
          return prevOrders.map(order => {
            if (order.ID === updatedModalData.ID) {
              return {
                ...order,
                encoding: updatedModalData.encoding,
                message_length: updatedModalData.message_length
              };
            }
            return order;
          });
        });
      } else {
        const data = await response.json();
        console.error(data.message);
        setErrorMessage(data.errors.templateName || data.errors.smsTemplate || "data.errors");
        setSuccessMessage('');
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorModalOpen(true);
      setSuccessMessage('');
      setErrorMessage('Error updating template');
      console.error('Fetch request error:', error);
    }

    toggleEditModal();
};

  // get 
  useEffect(() => {
    const fetchBuiltInTemplates = async () => {
      try {
        const response = await fetch(`${BASE_URL}/builtin-templates`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchBuiltInTemplates();
  }, []);

  // delete 
  const handleDeleteClick = (rowData) => {
    if (rowData.original && rowData.original.ID) {
      setEditingRowData(rowData);
      toggleDeleteModal();
    } else {
      console.error('Row data is null or id is not defined');
    }
  };

  const handleDeleteOrder = async (rowData) => {
    try {
      const id = rowData.original.ID;
      console.log(id);
      const response = await fetch(`${BASE_URL}/builtin-templates/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: rowData.original.ID }),
      });
      if (response.ok) {
        const updatedOrders = orders.filter(order => order.ID !== rowData.original.ID);
        setOrders(updatedOrders);
        setDeleteModal(false);
      } else {
        console.error('Failed to delete Badword group');
      }
    } catch (error) {
      console.error('Error deleting Badword group:', error);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const columns = useMemo(() => [
    // { Header: 'ID', accessor: 'ID', disableFilters: true, },
    { Header: 'Template Name', accessor: 'template_name', disableFilters: true, },
    { Header: 'SMS Template', accessor: 'template_message', disableFilters: true, },
    { Header: 'Unicode', accessor: 'sms_encoding', disableFilters: true, },
    { Header: 'Characters', accessor: 'sms_template_length', disableFilters: true, },
    { Header: 'SMS Cost', accessor: 'sms_no_of_messages', disableFilters: true, },
    {
      id: "action",
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <ul className="list-unstyled hstack gap-1 mb-0">
            <button
              className="btn btn-soft-success"
              onClick={() => handleEditClick(row)}
            >
              <i className="mdi mdi-plus font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Add Template
              </UncontrolledTooltip>
            </button>
          </ul>
        </div>
      ),
    }
  ], []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="SMS Templates" breadcrumbItem="Builtin SMS Templates" />
          <Modal isOpen={editModal} toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal}>
              Edit Builtin Template
            </ModalHeader>
            <div className="modal-body">
              <Label>Template Name</Label>
              <Input value={modalData.template_name} name="template_name" className="mb-3" onChange={handleInputChange} />

              <Label> Edit SMS Template</Label>
              <Input id="messageTextarea" ref={editModalMessage} value={modalData.template_message} name="template_message" className="mb-3" type="textarea" rows='10' onChange={handleInputChange} />
              {smsCounter && (
                <ul className="btn btn-soft-info waves-effect waves-light btn btn-secondary list-unstyled d-flex justify-content-between rounded">
                  <li>Encoding: {smsCounter.sms_encoding}</li>
                  <li>Length: {smsCounter.sms_template_length}</li>
                </ul>
              )}

              {successMessage && <p>{successMessage}</p>}
              {errorMessage && <p>{errorMessage}</p>}
            </div>
            <div className="modal-footer">
              <Button color="secondary" onClick={handleSubmit}>Save Template</Button>
            </div>
          </Modal>
          <SuccessModal
            isOpen={successModalOpen}
            toggle={toggleSuccessModal}
            successMessage={successMessage}
          />
          <ErrorModal
            isOpen={errorModalOpen}
            toggle={toggleErrorModal}
            errorMessage={errorMessage}
          />
          <DeleteModal
            backdrop="static"
            isOpen={deleteModal}
            toggle={() => setDeleteModal(false)}
            handleDeleteOrder={handleDeleteOrder}
            rowData={editingRowData}
            value={'SMS Template'}
          />

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <TableContainer
                    columns={columns}
                    data={orders.map((data, index) => ({ ...data, key: index }))}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    linkURL={'/add-builtin-template'}
                    linkValue={'Add Builtin Sms Template'}
                    customPageSize={10}
                    isPagination={true}
                    isActions={false}
                    handleDelete={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    className="table-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default ViewBuiltinTemplate;
