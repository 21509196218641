import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Form, Label, Input, Container, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BASE_URL from "Config/config";
import { error } from "toastr";

function AddContacts() {
    document.title = "Add Contacts";

    const [contactsName, setContactsName] = useState('');
    const [contactsEmail, setContactsEmail] = useState('');
    const [contactsNumber, setContactsNumber] = useState('');
    const [contactsAlternateNumber, setContactsAlternateNumber] = useState('');
    const [contactGroupValue, setContactGroupValue] = useState('');
    const [contactGroupDesc, setContactGroupDesc] = useState('');
    const [selectedContactGroup, setSelectedContactGroup] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const [optionMasking, setOptionMasking] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState('');

    const token = localStorage.getItem('token');

    const fetchContactGroups = async () => {
        try {
            const response = await fetch(`${BASE_URL}/contactgroups`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            const options = data.map(group => ({ value: group.id, label: group.GroupName }));
            setOptionMasking(options);
        } catch (error) {
            console.error('Error fetching contact groups:', error);
        }
    };

    useEffect(() => {
        fetchContactGroups();
    }, []);

    const handleChangehandleSelectContactGroup = (selectedOption) => {
        setSelectedContactGroup(selectedOption);
    };
    const [contactsNumberError, setContactsNumberError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'contactsNumber') {
            setContactsNumber(value);
        } else if (name === 'contactsEmail') {
            setContactsEmail(value);
        } else if (name === 'contactsName') {
            setContactsName(value);
        }
    };

    const handleGroupChange = (e) => {
        setContactGroupValue(e.target.value);
    }

    const handleGroupDescChange = (e) => {
        setContactGroupDesc(e.target.value);
    }

    const handleReset = () => {
        setContactsName('');
        setContactGroupValue('');
        setSelectedContactGroup(null);
        setSuccessMessage('');
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let cleanedNumber = contactsNumber.replace(/[^0-9]/g, '');

        let error = '';
        if (cleanedNumber.startsWith('0')) {
            if (cleanedNumber.length !== 11) {
                error = 'Number starting with 0 must be 11 digits long.';
                setContactsNumberError(error);
            }
            cleanedNumber = '92' + cleanedNumber.slice(1);
            setContactsNumber(cleanedNumber);

        } else if (cleanedNumber.startsWith('92')) {
            if (cleanedNumber.length !== 12) {
                error = 'Number starting with 92 must be 12 digits long.';
                setContactsNumberError(error);
            }
            setContactsNumber(cleanedNumber);
            cleanedNumber = '92' + cleanedNumber.slice(2);
            setContactsNumber(cleanedNumber);
        } else if (cleanedNumber.startsWith('3')) {
            if (cleanedNumber.length !== 10) {
                error = 'Number starting with 3 must be 10 digits long.';
                setContactsNumberError(error);
            } else {
                // Prepend '92' to the number if starting with '3' and exactly 10 digits
                cleanedNumber = '92' + cleanedNumber;
            }
        } else {
            error = 'Invalid number format.';
            setErrorMessage(error);
        }
        if (error) {
            setErrorModalOpen(true);
            setErrorMessage(error);
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/contacts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                body: new URLSearchParams({
                    'Name': contactsName,
                    'Contact_Email': contactsEmail,
                    'Cell_No': cleanedNumber, 
                    'Group_Name': selectedContactGroup ? selectedContactGroup.value : ''
                })
            });

            if (!response.ok) {
                const data = await response.json();
                console.log(data);
                setErrors(data.errors);
                setErrorMessage(data.message);
                setErrorModalOpen(true);
                setSuccessModalOpen(false);
                return;
            }

            const responseData = await response.json();
            setSuccessMessage(responseData.message);
            setContactsName('');
            setContactsEmail('');
            setContactsNumber('');
            setContactsAlternateNumber('');
            setSelectedContactGroup(null);
            setErrorModalOpen(false);
            setSuccessModalOpen(true);
        } catch (error) {
            setErrorMessage('Error adding contact');
            setErrorModalOpen(true);
            setSuccessModalOpen(false);
        }
    };

    const handleContactGroupSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/contactgroups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                body: new URLSearchParams({
                    'GroupName': contactGroupValue,
                    'GroupDescription': contactGroupDesc
                })
            });
            if (!response.ok) {
                const data = await response.json();
                setErrors(data.errors);
                setErrorMessage(data.message);
                setErrorModalOpen(true);
                setSuccessModalOpen(false);
                return;
            }
            const data = await response.text();
            setSuccessMessage('Contact group added successfully');
            console.log(data);
            setContactGroupValue('');
            setContactGroupDesc('');
            setErrorModalOpen(false);
            setSuccessModalOpen(true)
        } catch (error) {
            setErrorMessage('Error adding contact group');
            console.error('Error submitting form:', error);
            setErrorModalOpen(true);
            setSuccessModalOpen(false)
        }
    };

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const toggleSuccessModal = () => setSuccessModalOpen(!successModalOpen);
    const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Sent SMS" breadcrumbItem="Add Api Key" />
                    <Row>
                        <Col xl={12}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => setActiveTab("1")}
                                    >
                                        Create New Contact
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={activeTab === "2" ? "active" : ""}
                                        onClick={() => setActiveTab("2")}
                                    >
                                        Create New Contact Group
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                    <Row>
                                        <Col lg='12'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId='1'>
                                                    <CardTitle className="h4">Add Contacts </CardTitle>
                                                    <Col lg={8}>
                                                        <p className="card-title-desc">
                                                            Add Api Key is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                        </p>
                                                    </Col>
                                                    <Form className="outer-repeater" onSubmit={handleSubmit}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div data-repeater-list="outer-group" className="outer">
                                                                    <Row>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Contact Name : </Label>
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Please enter contact name"
                                                                                    name="contactsName"
                                                                                    value={contactsName}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {errors && errors.contactsName && <div style={{ color: 'red' }}>{errors.contactsName}</div>}                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Contact Email : </Label>
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Please enter contact email"
                                                                                    name="contactsEmail"
                                                                                    value={contactsEmail}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                {errors && errors.contactsEmail && <div style={{ color: 'red' }}>{errors.contactsEmail}</div>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Contact Number : </Label>
                                                                                <Input
                                                                                    type="tel"
                                                                                    className="form-control"
                                                                                    placeholder="Please enter contact number"
                                                                                    name="contactsNumber"
                                                                                    value={contactsNumber}
                                                                                    onChange={handleChange}
                                                                                    autoComplete="tel"
                                                                                />
                                                                                {errors && errors.contactsNumber && <div style={{ color: 'red' }}>{errors.contactsNumber}</div>}
                                                                                {contactsNumberError && <div style={{ color: 'red' }}>{contactsNumberError}</div>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="formname">Add Group : </Label>
                                                                                <Select
                                                                                    options={optionMasking}
                                                                                    value={selectedContactGroup}
                                                                                    onChange={handleChangehandleSelectContactGroup}
                                                                                />
                                                                                {errors && errors.selectedContactGroup && <div style={{ color: 'red' }}>{errors.selectedContactGroup}</div>}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex justify-content-end gap-2 mt-4">
                                                                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                        <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId='2'>
                                                    <CardTitle className="h4">Add Group Name </CardTitle>
                                                    <Col lg={8}>
                                                        <p className="card-title-desc">
                                                            Add Group Name is designed to simplify the process of sending short messages swiftly. With Add Api Key, you can effortlessly compose and dispatch brief messages, making communication a breeze. Streamlined and efficient, it's your go-to solution for sending quick, timely messages with ease
                                                        </p>
                                                    </Col>
                                                    <Form className="outer-repeater" onSubmit={handleContactGroupSubmit}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div data-repeater-list="outer-group" className="outer">
                                                                    <Form onSubmit={handleContactGroupSubmit}>
                                                                        <Row>
                                                                            <Col lg={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="formname">Group Name : </Label>
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        placeholder="Please enter group name"
                                                                                        value={contactGroupValue}
                                                                                        onChange={handleGroupChange}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={6}>
                                                                                <div className="mb-3">
                                                                                    <Label htmlFor="formname">Contact Group Description : </Label>
                                                                                    <Input
                                                                                        type="textarea"
                                                                                        className="form-control"
                                                                                        placeholder="Please enter group description"
                                                                                        value={contactGroupDesc}
                                                                                        onChange={handleGroupDescChange}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Form>
                                                                    <div className="d-flex justify-content-end gap-2 mt-4">
                                                                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                                                                        <button type="button" className="btn btn-secondary w-md" onClick={handleReset}>Reset</button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={successModalOpen} size="sm" toggle={toggleSuccessModal} centered>
                    <ModalHeader toggle={toggleSuccessModal} className="bg-success text-white">
                        Success
                    </ModalHeader>
                    <ModalBody className="text-center d-flex flex-column">
                        <i className="dripicons-checkmark h1 text-success"></i>
                        <p className="mt-3">{successMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleSuccessModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={errorModalOpen} size="sm" toggle={toggleErrorModal} centered>
                    <ModalHeader toggle={toggleErrorModal} className="bg-danger text-white">
                        Error
                    </ModalHeader>
                    <ModalBody className="text-center d-flex flex-column">
                        <i className="dripicons-wrong h1 text-danger"></i>
                        <p>{errorMessage}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            onClick={toggleErrorModal}
                            className="btn btn-light w-100"
                        >
                            Close
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AddContacts;
